import React, { useEffect, useMemo, useState } from "react";
import {
  BlockStack,
  Box,
  Card,
  Checkbox,
  Divider,
  InlineGrid,
  InlineStack,
  Select,
  Text,
  useBreakpoints,
} from "@shopify/polaris";
import { useProductEditContext } from "../ProductEditProvider";
import { DIProps, SelectOptionI } from "Src/Interface/@core";
import { DI } from "Src/core";

const VariantsMapping = ({ t }: DIProps) => {
  const {
    shopifyAttributeNames,
    variantAttributes,
    localVariants,
    updateLocalProduct,
  } = useProductEditContext();
  const {mdUp, mdDown} = useBreakpoints();
  const [isProfileMappingActive, setIsProfileMappingActive] = useState(false);
  const attrMap: any = useMemo(
    () =>
      localVariants?.[0]?.attribute_options
        ? JSON.parse(localVariants?.[0]?.attribute_options)
        : {},
    [localVariants]
  );
  const [attributesState, setAttributesState] = useState<
    {
      shopifyAttrName: string;
      etsyAttrId: string;
      scaleId?: string;
    }[]
  >([]);

  const propertIdScalesOptionMap: { [key: string]: SelectOptionI[] } =
    useMemo(() => {
      return variantAttributes.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.property_id]: curr.scales?.map((scale) => ({
            label: scale?.display_name,
            value: scale.scale_id?.toString(),
          })),
        };
      }, {});
    }, [variantAttributes]);

  const options: SelectOptionI[] = useMemo(
    () =>
      variantAttributes?.map((value: any) => ({
        label: value.display_name,
        value: value.property_id,
      })) ?? [],
    [variantAttributes]
  );

  const updateAttributeValue = (
    index: number,
    key: "etsyAttrId" | "scaleId",
    val: string
  ) => {
    if (!isProfileMappingActive) {
      const tempAttributeState = [...attributesState];
      if (key === "scaleId") {
        tempAttributeState[index].scaleId = val as string;
      } else {
        tempAttributeState[index].etsyAttrId = val;
        tempAttributeState[index].scaleId = propertIdScalesOptionMap[val]
          ? propertIdScalesOptionMap[val]?.[0]?.value
          : undefined;
      }
      setAttributesState(tempAttributeState);
    }
  };

  useEffect(() => {
    const attrState = shopifyAttributeNames.map((name) => {
      if (isProfileMappingActive) {
        return {
          shopifyAttrName: name,
          etsyAttrId: "",
        };
      }
      const found: any[] | undefined = Object.entries(attrMap).find(
        ([key, val]: any) => val?.property_name === name
      );
      const attrObj = variantAttributes.find(
        (attr) => attr.property_id === found?.[0]
      );
      return {
        shopifyAttrName: name,
        etsyAttrId: found?.[0] ?? "",
        scaleId: found?.[1]?.scale_id,
        scalesOptions:
          attrObj?.scales?.map((scale) => ({
            label: scale?.display_name,
            value: scale.scale_id?.toString(),
          })) ?? [],
      };
    });
    setAttributesState([...attrState]);
  }, [
    shopifyAttributeNames,
    attrMap,
    variantAttributes,
    isProfileMappingActive,
  ]);

  useEffect(() => {
    let option_name = {};
    let option_name_selected = {};
    attributesState.forEach((attr) => {
      Object.assign(option_name, {
        [attr.shopifyAttrName]: attr.etsyAttrId ?? null,
      });
      if (attr.scaleId) {
        Object.assign(option_name_selected, {
          [attr.etsyAttrId]: { scale_id: attr.scaleId },
        });
      }
    });
    updateLocalProduct({
      option_name: isProfileMappingActive ? null : option_name,
      option_name_selected: isProfileMappingActive
        ? null
        : option_name_selected,
    });
  }, [attributesState]);

  useEffect(() => {
    setIsProfileMappingActive(Object.keys(attrMap).length === 0);
  }, [attrMap]);

  return (
    <BlockStack gap="100">
      <InlineStack align="space-between">
        <Text as={"strong"} variant="headingMd" fontWeight="medium">
          {t("VARIANTS_MAPPING")}
        </Text>
        <Checkbox
          label="Use profile level mapping"
          checked={isProfileMappingActive}
          onChange={() => {
            setIsProfileMappingActive((prev) => !prev);
          }}
        />
      </InlineStack>
      <Card roundedAbove="xs">
        <BlockStack gap="200">
        {mdUp &&  <InlineGrid columns={["oneThird", "twoThirds"]}>
            <Text as="h6" variant="headingMd" fontWeight="semibold">
              {t("ATTRIBUTES")}
            </Text>
            <Text as="h6" variant="headingMd" fontWeight="semibold">
              {t("VALUE")}
            </Text>
          </InlineGrid>}
          {attributesState.map((attrObj, index) => {
            return (
                <InlineGrid columns={mdUp ? ["oneThird", "twoThirds"] : 1} gap={mdDown ? "100" : "300"}>
                  {mdDown &&   <Text as="h6" variant="headingMd" fontWeight="semibold">
                  {t("ATTRIBUTES")}
                </Text>}
                <Text as="span" variant="bodyMd">
                  {attrObj.shopifyAttrName}
                </Text>
                {mdDown &&   <Text as="h6" variant="headingMd" fontWeight="semibold">
                  {t("VALUE")}
                  </Text>}
                <InlineGrid
                  gap="200"
                  columns={
                    propertIdScalesOptionMap?.[attrObj?.etsyAttrId] ? 2 : 1
                  }
                >
                  <Select
                    label=""
                    placeholder={t("SELECT_OPTION")}
                    labelHidden
                    options={options}
                    onChange={(val) => {
                      updateAttributeValue(index, "etsyAttrId", val);
                    }}
                    value={attrObj.etsyAttrId}
                    disabled={isProfileMappingActive}
                  />
                  {propertIdScalesOptionMap?.[attrObj?.etsyAttrId] ? (
                    <Select
                      label=""
                      placeholder={t("SELECT_OPTION")}
                      labelHidden
                      value={attrObj?.scaleId}
                      options={propertIdScalesOptionMap?.[attrObj?.etsyAttrId]}
                      onChange={(val) => {
                        updateAttributeValue(index, "scaleId", val);
                      }}
                      disabled={isProfileMappingActive}
                    />
                  ) : null}
                            {mdDown  && index !== attributesState.length - 1 && (<Box paddingBlockStart='300' paddingBlockEnd="200"><Divider/></Box>)}

                </InlineGrid>
              </InlineGrid>
            );
          })}
        </BlockStack>
      </Card>
    </BlockStack>
  );
};

export default DI(VariantsMapping);
