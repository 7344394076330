import React, { useMemo, useState } from "react";
import {
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  IndexTable,
  InlineStack,
  Select,
  Text,
  TextField,
  useIndexResourceState,
} from "@shopify/polaris";
import ModalAppBridge from "Src/Component/HelperComponents/ModalAppBridge";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";
import { useProductEditContext } from "../ProductEditProvider";
import { apiUrls } from "Src/Constant/url";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { regexExpressions } from "Src/Constants";
import LocationWiseRow from "./LocationWiseRow";

interface PropsI extends DIProps {
  isOpen: boolean;
  toggleLocationWiseModal: () => void;
}

const LocationWiseInventoryModal = ({
  isOpen,
  toggleLocationWiseModal,
  request: { POST },
  redux,
  t,
}: PropsI) => {
  const { localVariants } = useProductEditContext();
  const [applyToAllVal, setApplyToAllVal] = useState("");
  const [appliedAllValue, setAppliedAllValue] = useState("");
  const [appliedAllTrigger, setAppliedAllTrigger] = useState(false);
  const [inventoryVariants, setInventoryVariants] = useState<any[]>([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [locationState, setLocationState] = useState({
    active: "",
    loading: false,
  });

  const inventoryItemIds = useMemo(
    () => localVariants.map((variant) => variant?.inventory_item_id),
    [localVariants]
  );

  const fetchInventoryData = (locationId: string) => {
    setLocationState((prev) => ({ ...prev, loading: true }));
    const payload = {
      location_id: locationId,
      inventory_item_ids: inventoryItemIds,
    };
    POST(apiUrls.getInventoryEditGrid, payload)
      .then((res) => {
        if (res?.success) {
          // issue to be resolved
          setInventoryVariants(res?.locationwiseInventory);
        } else {
          ToastAppBridge(res?.message, { isError: true });
        }
      })
      .finally(() => {
        setLocationState((prev) => ({ ...prev, loading: false }));
      });
  };

  const saveInventoryData = () => {
    if (inventoryVariants.length === 0 || !locationState.active) {
      return;
    }
    setSaveLoading(true);
    const payload = {
      location_id: locationState.active,
      Inventory: inventoryVariants.reduce((prev, curr) => {
        return { ...prev, [curr?.inventory_item_id]: curr?.available };
      }, {}),
    };
    POST(apiUrls.saveLocationWiseInventory, payload)
      .then((res) => {
        if (res?.success) {
          ToastAppBridge(res?.message);
          toggleLocationWiseModal();
        } else {
          ToastAppBridge(res?.message, { isError: true });
        }
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const locationOptions = Object.entries(
    redux?.settings?.locationMap ?? {}
  ).map(([id, label]) => {
    return { label, value: id };
  });

  const resourceName = {
    singular: "product",
    plural: "products",
  };

  const { selectedResources, handleSelectionChange } =
    useIndexResourceState(inventoryVariants);

  const updateInventoryVariants = (
    index: number,
    key: string,
    value: string
  ) => {
    const tempVariants = [...inventoryVariants];
    Object.assign(tempVariants?.[index], { [key]: value });
    setInventoryVariants([...tempVariants]);
  };

  const updateAllAvailable = () => {
    setInventoryVariants((prev) =>
      prev.map((variant) => ({ ...variant, available: applyToAllVal }))
    );
    setAppliedAllTrigger(true);
    setAppliedAllValue(applyToAllVal);
  };

  const rowMarkup = inventoryVariants.map((rowItem, index) => (
    <IndexTable.Row
      id={rowItem?.inventory_item_id}
      key={rowItem?.inventory_item_id}
      selected={selectedResources.includes(rowItem?.inventory_item_id)}
      position={index}
    >
      <LocationWiseRow
        index={index}
        rowItem={rowItem}
        updateRow={updateInventoryVariants}
        appliedAllValue={appliedAllValue}
        appliedAllTrigger={appliedAllTrigger}
        toggleAppliedAllTrigger={() => setAppliedAllTrigger((prev) => !prev)}
      />
    </IndexTable.Row>
  ));

  return (
    <ModalAppBridge
      open={isOpen}
      id="location-wise-inventory"
      variant="base"
      onHide={toggleLocationWiseModal}
      title={t("LOCATION_WISE_INVENTORY")}
      body={
        <Box padding="400">
          <BlockStack gap="300">
            <Text variant="bodyMd" as="p">
              {t(
                "SELECT_THE_INVENTORY_LOCATION_WHERE_YOU_WANT_TO_UPDATE_THE_STOCK"
              )}
            </Text>
            <Divider />
            <Select
              label={t("SELECT_LOCATION")}
              placeholder={t("SELECT")}
              value={locationState.active}
              onChange={(val) => {
                setLocationState((prev) => ({ ...prev, active: val }));
                fetchInventoryData(val);
              }}
              options={locationOptions}
            />
            <InlineStack
              align="space-between"
              blockAlign="end"
              wrap={false}
              gap="300"
            >
              <div style={{ flex: "1" }}>
                <TextField
                  label={t("APPLY_TO_ALL_VARIANTS")}
                  placeholder={t("ENTER_VALUE")}
                  value={applyToAllVal}
                  onChange={(val) => {
                    if (
                      regexExpressions.NUMBER_WITHOUT_DOT.test(val) ||
                      val === ""
                    ) {
                      setApplyToAllVal(val);
                    }
                  }}
                  autoComplete=""
                />
              </div>
              <Button
                disabled={!locationState.active}
                onClick={updateAllAvailable}
              >
                {t("APPLY_TO_ALL")}
              </Button>
            </InlineStack>
            {locationState.active ? (
              locationState.loading ? (
                <>Loading...</>
              ) : (
                <Card padding="0">
                  <IndexTable
                    resourceName={resourceName}
                    itemCount={inventoryVariants.length}
                    selectable={false}
                    onSelectionChange={handleSelectionChange}
                    headings={[
                      { title: t("VARIANT_DETAILS") },
                      { title: t("INVENTORY") },
                    ]}
                  >
                    {rowMarkup}
                  </IndexTable>
                </Card>
              )
            ) : null}
          </BlockStack>
        </Box>
      }
      footer={
        <>
          <button
            variant="primary"
            loading={saveLoading && "true"}
            onClick={saveInventoryData}
            disabled={inventoryVariants.length === 0 || !locationState.active}
          >
            {t("SAVE")}
          </button>
          <button onClick={toggleLocationWiseModal}>{t("CANCEL")}</button>
        </>
      }
    />
  );
};

export default DI(LocationWiseInventoryModal);
