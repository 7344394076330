import { scrollToSection } from "Src/Component/HelperComponents/Scroll";
import {
  BasicMappingKeys,
  InitialI,
  RuleGroupI,
  TemplatesI,
} from "Src/Interface/@Profile";
import { formatRuleGroup } from "./CreateProfileHelper";
import { disabledRuleGroupOperators } from "Src/Constant/helpData";

export const handleSaveProfile = (
  isDIGITAL: boolean,
  t: any,
  profileContext: InitialI,
  product_ids?: string[],
  select_all?: number,
  filters?: Object
) => {
  const { setsave_loading } = profileContext;
  setsave_loading(true);
  const isValid = validateForm(
    isDIGITAL,
    profileContext,
    t,
    product_ids !== undefined
  );
  if (!isValid) {
    return false;
  }
  return formatPayload(
    isDIGITAL,
    profileContext,
    product_ids,
    select_all,
    filters
  );
};

const validateForm = (
  isDIGITAL: boolean,
  {
    templates,
    rule_group,
    saveError,
    profile: { profile_code },
    setsave_loading,
    basicMapping,
    variationAttributesState,
    error,
  }: InitialI,
  t: any,
  skip?: boolean
) => {
  const tempErr: { [key: string]: any } = { ...error };

  const Reqmsg = t("CST_REQUIRED_MSG");

  if (Object.values(basicMapping.requiredAttributes)?.length > 0) {
    Object.values(basicMapping?.requiredAttributes)?.forEach(
      (attr: any, index: number) => {
        const values: any = Object.values(attr)?.[0];
        if (
          (typeof values === "string" && !values) ||
          (Array.isArray(values) && values.length === 0)
        ) {
          tempErr[`requiredAttributes_${index}`] = Reqmsg;
        }
      }
    );
  }
  if (variationAttributesState.length > 0) {
    variationAttributesState.forEach((attr, index) => {
      if (
        (typeof attr.etsyValue === "string" && !attr?.etsyValue) ||
        (Array.isArray(attr.etsyValue) && attr.etsyValue.length === 0)
      ) {
        tempErr[`variation_mapping_${index}_etsy_value`] = Reqmsg;
      }
      if (
        (typeof attr.shopifyValue === "string" && !attr?.shopifyValue) ||
        (Array.isArray(attr.shopifyValue) && attr.shopifyValue.length === 0)
      ) {
        tempErr[`variation_mapping_${index}_shopify_value`] = Reqmsg;
      }
    });
  }
  if (
    basicMapping.is_personalizable === "1" &&
    basicMapping.personalization_is_required === "1"
  ) {
    if (basicMapping.personalization_char_count_max === "") {
      tempErr[`personalization_char_count_max`] = "Limit cannot be empty";
    } else {
      const charLimit = parseInt(basicMapping.personalization_char_count_max);
      if (
        !isNaN(charLimit) &&
        (basicMapping.personalization_instructions.length > charLimit ||
          basicMapping.personalization_instructions === "")
      ) {
        tempErr[
          "personalization_instructions"
        ] = `Personalization instructions must be between 1 to ${charLimit} characters`;
      }
    }
  }
  if (profile_code.trim() === "") {
    tempErr["profile_code"] = Reqmsg;
  }

  const ReqAboutlisting: BasicMappingKeys[] = [
    "categoryId",
    "isSupply",
    "whoMade",
    "whenMade",
  ];
  if (!skip)
    rule_group.ruleRows.forEach(
      (
        rule: { property: string; operator: string; value: string[] },
        index: number
      ) => {
        if (rule.operator.trim() === "") {
          tempErr["operator_" + index] = Reqmsg;
        }

        if (rule.property.trim() === "") {
          tempErr["property_" + index] = Reqmsg;
        }
        if (rule.value.length < 1 || rule.value[0].trim() === "") {
          tempErr["value_" + index] = Reqmsg;
        }
      }
    );
  ReqAboutlisting.forEach((aboutItem) => {
    if (!basicMapping[aboutItem]) {
      tempErr[aboutItem] = Reqmsg;
    }
  });

  const ReqTemplates: (keyof TemplatesI)[] = [
    "shipping_templates",
    "policy_templates",
  ];

  !isDIGITAL &&
    ReqTemplates.forEach((templateName) => {
      if (!templates[templateName]) {
        tempErr[templateName] = Reqmsg;
      }
    });
  saveError(tempErr);
  const errorKeys = Object.keys(tempErr);
  if (errorKeys.length > 0) {
    scrollToSection(errorKeys[0]);
    setsave_loading(false);
    return false;
  }
  return true;
};

const formatPayload = (
  isDIGITAL: boolean,
  { profile, templates, rule_group, totalProduct, basicMapping }: InitialI,
  product_ids: string[] | undefined,
  select_all?: number,
  filters?: Object
) => {
  let isDigitalProfile = "0";
  if (isDIGITAL) {
    isDigitalProfile = "1";
  }

  const personalize = {
    is_personalizable: basicMapping.is_personalizable,
  };
  if (basicMapping.is_personalizable === "1") {
    Object.assign(personalize, {
      personalization_is_required: basicMapping.personalization_is_required,
      personalization_char_count_max:
        basicMapping.personalization_char_count_max,
      personalization_instructions: basicMapping.personalization_instructions,
    });
  }
  const rule_grp_payload = {
    product_profile: {
      ...formatRuleGroup(rule_group.ruleRows),
      condition: rule_group.condition,
    },
    triggerType: rule_group.select_all === "1" ? "automatic" : "manual",
    selection_all: rule_group.select_all,
    update_on_shopify: rule_group.update_on_shopify,
  };

  const payloadForAllSelected = {
    select_all,
    filters,
  };

  const payloadForPublish = {
    update_on_shopify: product_ids?.length === 0 ? undefined : product_ids,
    profile_on_upload: true,
  };

  if (select_all === 1) {
    Object.assign(payloadForPublish, payloadForAllSelected);
  }
  const all_template: any = templates;

  if (isDIGITAL) {
    delete all_template["shipping_templates"];
    delete all_template["policy_templates"];
  }
  const payload = {
    ...(product_ids ? payloadForPublish : rule_grp_payload),
    Profile: profile,
    templates: templates,
    isDigitalProfile: isDigitalProfile,
    category_management: {
      categoryTree: basicMapping.categoryTree,
      requiredAttributes: {
        whoMade: basicMapping.whoMade,
        isSupply: basicMapping.isSupply,
        whenMade: basicMapping.whenMade,
        should_auto_renew: basicMapping.should_auto_renew,
        shopSectionId: basicMapping.shopSectionId,
        image_linking_option: basicMapping.image_linking_option,
        production_partner_ids: basicMapping.production_partner_ids,
      },
      personalize,
      styles: basicMapping?.styles,
      materials: basicMapping?.materials,
      variantAttributes_shopify: basicMapping.variantAttributes_shopify,
      variantAttributes_etsy: basicMapping.variantAttributes_etsy,
      option_name_selected: basicMapping.option_name_selected,
      recommendedAttributes: {
        ...basicMapping.recommendedAttributes,
        ...basicMapping.requiredAttributes,
      },
    },
  };

  return payload;
};

export const UpdateContextForEdit = (
  profileContext: InitialI,
  data: any,
  type: "edit" | "create" | "clone"
) => {
  const {
    saveTemplates,
    updateProfile,
    updateRuleGroup,
    saveBasicMapping,
    updateTotalCount,
  } = profileContext;
  const editData = data?.mappedCategoryData?.category_management?.mappedData;
  // Updated the mappging Data and category
  const basicMapping = {
    ...editData?.requiredAttributes,
    ...editData.personalize,
    categoryId: editData.taxonomy_id,
    categoryTree: editData.categoryTree,
    materials: editData.materials?.split(","),
    styles: editData.styles?.split(","),
    variantAttributes_shopify: [],
    variantAttributes_etsy: [],
    recommendedAttributesEdit: editData.recommendedAttributes,
    recommendedAttributes: {},
    requiredAttributes: [],
    option_name_selected: {},
    option_name_selected_edit: editData?.option_name_selected,
    variantAttributesEdit: editData.variantAttributes,
  };
  saveBasicMapping(basicMapping);
  saveTemplates("shipping_templates", data.mappedShippingData.toString() ?? "");
  saveTemplates("policy_templates", data.mappedPolicyData ?? "");
  saveTemplates("price_templates", data.mappedPriceData ?? "");
  saveTemplates("inventory_templates", data.mappedInventoryData ?? "");
  updateProfile("profile_code", data.mappedProfileCode);
  updateProfile("profile_code_existing", data.mappedProfileCode);
  if (type !== "clone") {
    updateProfile("ProfileId", data.profileId);
    updateTotalCount(data.is_digital_profile);

    updateRuleGroup((prev) => {
      const tempData: RuleGroupI = {
        condition: "||",
        ruleRows: [],
        select_all: "1",
        update_on_shopify: [],
      };

      if (data.mappedProductProfileData?.condition) {
        tempData.condition = data.mappedProductProfileData?.condition;
      }
      if (
        data.mappedProductProfileData?.Product_property_selected?.length > 0
      ) {
        data.mappedProductProfileData?.Product_property_selected?.forEach(
          (property: any, index: number) => {
            const operator =
              data.mappedProductProfileData?.Operator_selected_for_property?.[
                index
              ] ?? "";
            const value =
              data.mappedProductProfileData?.values_of_select_and_input?.[
                index
              ] ?? [];
            const type =
              disabledRuleGroupOperators?.[property]?.field?.[operator] ??
              "input";
            tempData.ruleRows.push({ property, operator, value, type });
          }
        );
      }

      return tempData;
    });
  }
};
