import { BlockStack, Box, IndexTable, InlineStack, SkeletonBodyText, SkeletonDisplayText, Text, useBreakpoints, useIndexResourceState } from '@shopify/polaris'
import React from 'react'
// import './skeleton.css'

export default function ProfileGridSkeleton() {
    const { mdDown } = useBreakpoints();

    const gridSkeletonRow = {
        id: '1',
        profileName: <InlineStack gap={"400"} blockAlign='center'>
            <div className='toggle_skeleton'>
                <SkeletonBodyText lines={1} />
            </div>
            <div className='failed-order-skelton' style={{ 'width': '170px' }}><SkeletonBodyText lines={1} /></div>
        </InlineStack>,
        createdOn: <div className='failed-order-skelton' style={{ 'width': '100px' }}><SkeletonBodyText lines={1} /></div>,
        status: <div className='failed-order-skelton' style={{ 'width': '90px' }}><SkeletonBodyText lines={1} /></div>,
        productAssigned: <div className='failed-order-skelton' style={{ 'width': '100px' }}><SkeletonBodyText lines={1} /></div>,
        productType: <div className='failed-order-skelton' style={{ 'width': '70px' }}><SkeletonBodyText lines={1} /></div>,

        Action: <InlineStack gap={"200"}>
            <div style={{ 'width': '28px' }}>
                <SkeletonDisplayText size="small" />
            </div>
            <div style={{ 'width': '28px' }}>
                <SkeletonDisplayText size="small" />
            </div>
            <div style={{ 'width': '28px' }}>
                <SkeletonDisplayText size="small" />
            </div>
        </InlineStack>,
    }

    const resourceName = {
        singular: 'order',
        plural: 'orders',
    };

    const rowMarkupData = Array.from({ length: 10 }, () => gridSkeletonRow);
    const { selectedResources, allResourcesSelected, handleSelectionChange } =
        useIndexResourceState(rowMarkupData);

    const rowMarkup = rowMarkupData.map((item, index) => (
        <IndexTable.Row
            id={item.id}
            key={index}
            position={index}
        >
            <IndexTable.Cell>
                {item.profileName}
            </IndexTable.Cell>
            <IndexTable.Cell>
                {item.createdOn}
            </IndexTable.Cell>
            <IndexTable.Cell>{item.status}</IndexTable.Cell>
            <IndexTable.Cell>{item.productAssigned}</IndexTable.Cell>
            <IndexTable.Cell>{item.productType}</IndexTable.Cell>

            <IndexTable.Cell>{item.Action}</IndexTable.Cell>

        </IndexTable.Row>
    ),
    );

    const rowMarkupMobile = rowMarkupData.map((item, index) => (
        <IndexTable.Row
            id={item.id}
            key={index}
            position={index}
        >
             <IndexTable.Cell>
            <Box padding='300'>
                <InlineStack wrap={false} gap='200' align="space-between" blockAlign="start">
                    <InlineStack wrap={false} gap='200' blockAlign="start">
                        <div className="inte-custom__switcher">
                            <div className='toggle_skeleton '>
                                <SkeletonBodyText lines={1} />
                            </div>
                        </div>

                        <BlockStack gap='200'>
                            <InlineStack gap='200' blockAlign="start" align="start" wrap={false}>
                                <div className='failed-order-skelton skeleton-height-20' style={{ 'width': '170px' }}><SkeletonBodyText lines={1} /></div>
                                <div className='failed-order-skelton skeleton-height-20' style={{ 'width': '50px' }}><SkeletonBodyText lines={1} /></div>
                            </InlineStack>

                            <div className='failed-order-skelton custom-height_skel' style={{ 'width': '100px' }}><SkeletonBodyText lines={1} /></div>
                            <div className='failed-order-skelton custom-height_skel' style={{ 'width': '70px' }}><SkeletonBodyText lines={1} /></div>
                            <div className='failed-order-skelton custom-height_skel' style={{ 'width': '100px' }}><SkeletonBodyText lines={1} /></div>
                        </BlockStack>
                    </InlineStack>

                    <div style={{ 'width': '32px' }} className='skeleton-height-32'>
                    <SkeletonBodyText lines={1} />
                    </div>
                </InlineStack>
            </Box>
            </IndexTable.Cell>
        </IndexTable.Row>
    ),
    );


    const profilename: any = <InlineStack gap='200'><Box minWidth="35px"></Box>
        Name</InlineStack>
    return (
        <IndexTable

            selectable={false}
            resourceName={resourceName}
            itemCount={rowMarkupData.length}
            selectedItemsCount={
                allResourcesSelected ? 'All' : selectedResources.length
            }
            onSelectionChange={handleSelectionChange}
            headings={[
                { title: profilename },
                { title: 'Created On' },
                { title: 'Status' },
                { title: 'Products' },
                { title: 'Profile Type' },
                { title: 'Actions' },
            ]}
        >
            {mdDown ? rowMarkupMobile : rowMarkup}
        </IndexTable>

    )
}
