import { DIProps, ObjStrI } from "Src/Interface/@core";
import {
  getActivitySlice,
  toggleNewNotification,
} from "Src/Redux/Slices/activity";

export const webSocket = (token: any, _props: DIProps) => {
  //function for socket connection
  if ("WebSocket" in window) {
    var ws = new WebSocket(
      "wss://a5zls8ce93.execute-api.us-east-2.amazonaws.com/beta"
    );
    var send_activity =
      '{ "action": "identity","client_id":3,"customer_id":1,"token":"' +
      token +
      '"}';
    ws.onopen = function () {
      ws.send(send_activity);
      console.log("WebSocket is Active ");
    };
    ws.onmessage = function (evt) {
      var received_msg = evt.data;
      var socket_response = JSON.parse(received_msg);

      if (socket_response.feed || socket_response.notification) {
        _props.dispatch(
          getActivitySlice({
            data: {
              success: true,
              ongoing_activity: socket_response.feed,
              completed_activity: socket_response.notification,
            },
          })
        );
        const prevFeedIds: string[] =
          _props.redux.activity.activityData?.completed_activity?.map(
            (activity: ObjStrI) => activity?.feed_id
          ) ?? [];
        const newActivityAvailable = socket_response?.notification?.some?.(
          (activity: ObjStrI) => !prevFeedIds.includes(activity?.feed_id)
        );
        if (!_props.redux.activity.newNotificationAvailable && newActivityAvailable) {
          _props.dispatch(toggleNewNotification(true));
        }
      }
    };
    ws.onclose = function () {
      console.log("Connection is closed...");
    };
  } else {
    console.log("WebSocket NOT supported by your Browser!");
  }
};
