import React, { useCallback, useEffect, useState } from "react";

import {
  BlockStack,
  Box,
  Button,
  Divider,
  Icon,
  InlineStack,
  Page,
  Tabs,
  Text,
  useBreakpoints,
} from "@shopify/polaris";
import { apiUrls } from "Src/Constant/url";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";
import ModalAppBridge from "../HelperComponents/ModalAppBridge";
import { EtsyLogo } from "Src/assets/icons/Icons";

import { ExternalSmallIcon } from "@shopify/polaris-icons";
import { useProductsContext } from "./ProductsProvider";
import EtsyProductView from "./View/EtsyProductView";
import ShopifyProductView from "./View/ShopifyProductView";
import { getEtsyBadge, getShopifyBadge } from "./function";
import ProductViewSkeleton from "./View/Skeleton/ProductViewSkeleton";
import { isValidValue } from "Src/function";

const { viewListing, viewProductUpdates } = apiUrls;
const ProductView = ({ request: { GET }, t }: DIProps) => {
  const {mdUp} = useBreakpoints();
  const { viewModalState, toggleViewModal } = useProductsContext();
  const [selected, setSelected] = useState(viewModalState.tab);
  const [hasBeenLoading, setHasBeenLoading] = useState(false);
  const [productData, setProductData] = useState<any>({});
  const [productUpdates, setProductUpdates] = useState<any>({});
  const getProductView = () => {
    const params = {
      listing_id: viewModalState?.listingId,
      product_id: viewModalState?.id,
    };
    const updateParams = {
      listingId: viewModalState?.listingId,
      productId: viewModalState?.id,
    };
    GET(viewProductUpdates, updateParams)
      .then((e) => {
        if (e?.success) {
          setProductUpdates(e?.data);
        }
      })
      .finally(() => {
        // setHasBeenLoading(true);
      });
    GET(viewListing, params)
      .then((e) => {
        if (e?.success) {
          setProductData(e?.data);
        }
      })
      .finally(() => {
        setHasBeenLoading(true);
      });
  };
  useEffect(() => {
    viewModalState.isOpen && getProductView();
  }, [viewModalState.isOpen]);

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelected(selectedTabIndex),
    []
  );
  const tabs = [
    {
      id: "shpify-view",
      content: "Shopify",
    },
  ];
  if (viewModalState.listingId !== "") {
    tabs.push({
      id: "etsy-view",
      content: "Etsy",
    });
  }

  return (
    <div className="product-view-modal">
      <ModalAppBridge
        variant="max"
        onHide={() => {
          toggleViewModal({ isOpen: false, id: "", listingId: "", tab: 0 });
        }}
        body={
          hasBeenLoading ? (
            <>
              <div className="custom_layout_width">
              <Box paddingInlineStart={{xs:'200', sm:"0"}} paddingInlineEnd={{xs:'200', sm:"0"}}>
                <Page fullWidth>
                  {selected === 0 ? (
                    !isValidValue(productData?.data?.errors) ? (
                      <InlineStack gap={"200"} blockAlign="center">
                        <Text as="h6" variant="headingMd">
                          {productData?.data?.title}{" "}
                          {productData?.data?.status &&
                            getShopifyBadge(productData?.data?.status,t)}
                        </Text>
                      </InlineStack>
                    ) : (
                      <></>
                    )
                  ) : !isValidValue(
                      productData?.product?.exception?.last_response
                    ) ? (
                    <InlineStack gap={"200"} blockAlign="center">
                      <Text as="h6" variant="headingMd">
                        {productData?.product?.title}{" "}
                        {getEtsyBadge(productData?.product?.state,t)}
                      </Text>
                      {productData?.product?.state === "active" && (
                        <Button
                          onClick={() => {
                            window.open(productData?.product?.url, "_blank");
                          }}
                          icon={
                            <InlineStack>
                              <EtsyLogo />
                              <Icon source={ExternalSmallIcon} tone="base" />
                            </InlineStack>
                          }
                        />
                      )}
                    </InlineStack>
                  ) : (
                    <></>
                  )}
                </Page>
                </Box>
              </div>
             {mdUp && <Divider />}
              <div className="custom_layout_width">
                <Page fullWidth>
                  <BlockStack gap={"200"}>
                    <div className="custom_tab_padding">
                      <Tabs
                        tabs={tabs}
                        selected={selected}
                        onSelect={handleTabChange}
                      />
                    </div>
                    {Object.keys(productUpdates).length !== 0 ? (
                      selected === 0 ? (
                        <ShopifyProductView
                          productUpdates={productUpdates}
                          productData={productData}
                          hasBeenLoading={hasBeenLoading}
                        />
                      ) : (
                        <EtsyProductView
                          productUpdates={productUpdates}
                          productData={productData}
                          hasBeenLoading={hasBeenLoading}
                        />
                      )
                    ) : (
                      <></>
                    )}
                  </BlockStack>
                </Page>
              </div>
            </>
          ) : (
            <ProductViewSkeleton />
          )
        }
        open={viewModalState.isOpen}
        title={""}
        id={""}
      />
    </div>
  );
};

export default DI(ProductView);
