import {
  BlockStack,
  IndexTable,
  InlineStack,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonThumbnail,
  useIndexResourceState,
} from "@shopify/polaris";
import React from "react";

export default function ProductListingGridSkeleton({
  selectedTabIndex = 0,
  heading = [],
}: {
  selectedTabIndex?: number;
  heading: any;
}) {
  let gridSkeletonRow: any = {};
  switch (selectedTabIndex) {
    //All Products
    case 0:
      gridSkeletonRow = {
        id: "1",
        productName: (
          <div className="failed-order-skelton" style={{ width: "450px" }}>
            <InlineStack gap={"200"} wrap={false} blockAlign="center">
              <SkeletonThumbnail size="small" />
              <SkeletonBodyText lines={2} />
              {/* <div className='failed-order-skelton' style={{ 'width': '170px' }}><SkeletonBodyText lines={1} /></div> */}
            </InlineStack>
          </div>
        ),
        rating: (
          <div className="failed-order-skelton" style={{ width: "30px" }}>
            <SkeletonBodyText lines={1} />
          </div>
        ),
        reviewer: (
          <BlockStack gap={"100"}>
            <InlineStack gap={"200"} blockAlign="center">
              <SkeletonThumbnail size="extraSmall" />
              <div style={{ "width": "50px" }}>
                <SkeletonBodyText lines={1} />
              </div>
            </InlineStack>
            <InlineStack gap={"200"} blockAlign="center">
              <SkeletonThumbnail size="extraSmall" />
              <div style={{ "width": "50px" }}>
                <SkeletonBodyText lines={1} />
              </div>
            </InlineStack>
          </BlockStack>
        ),
        comment: (
          <div className="failed-order-skelton" style={{ width: "150px" }}>
            <SkeletonBodyText lines={1} />
          </div>
        ),
        additionalImages: (
          <div className="failed-order-skelton" style={{ width: "70px" }}>
            <SkeletonBodyText lines={1} />
          </div>
        ),
        reviewCreationDate: (
          <div className="failed-order-skelton" style={{ width: "70px" }}>
            <SkeletonBodyText lines={1} />
          </div>
        ),
        reviewCreationDate1: (
          <div className="failed-order-skelton" style={{ width: "70px" }}>
            <SkeletonBodyText lines={1} />
          </div>
        ),
        Action: (
          <InlineStack gap={"200"}>
            <div style={{ width: "28px" }}>
              <SkeletonDisplayText size="small" />
            </div>
          </InlineStack>
        ),
      };
      break;
    //Not Linked
    case 1:
      gridSkeletonRow = {
        id: "1",
        productReviewed: (
          <div className="failed-order-skelton" style={{ width: "160px" }}>
            <InlineStack gap={"200"} wrap={false} blockAlign="center">
              <SkeletonThumbnail size="small" />
              <SkeletonBodyText lines={2} />
              {/* <div className='failed-order-skelton' style={{ 'width': '170px' }}><SkeletonBodyText lines={1} /></div> */}
            </InlineStack>
          </div>
        ),
        rating: (
          <div className="failed-order-skelton" style={{ width: "300px" }}>
            <InlineStack gap={"200"} wrap={false} blockAlign="center">
              <SkeletonThumbnail size="extraSmall" />
              <SkeletonBodyText lines={2} />
              {/* <div className='failed-order-skelton' style={{ 'width': '170px' }}><SkeletonBodyText lines={1} /></div> */}
            </InlineStack>
          </div>
        ),
        reviewer: (
          <InlineStack gap={"200"} wrap={false} blockAlign="center">
            <SkeletonThumbnail size="extraSmall" />
            <div style={{ width: "60px" }}>
              <SkeletonBodyText lines={2} />
            </div>
          </InlineStack>
        ),
        status: (
          <SkeletonThumbnail size="extraSmall" />
        ),
      };
      break;
    // Linked
    case 2:
      gridSkeletonRow = {
        id: "1",
        productReviewed: (
          <div className="failed-order-skelton" style={{ width: "160px" }}>
            <InlineStack gap={"200"} wrap={false} blockAlign="center">
              <SkeletonThumbnail size="small" />
              <SkeletonBodyText lines={2} />
            </InlineStack>
          </div>
        ),
        rating: (
          <div className="failed-order-skelton" style={{ width: "160px" }}>
            <InlineStack gap={"200"} wrap={false} blockAlign="center">
              <SkeletonThumbnail size="small" />
              <SkeletonBodyText lines={2} />
            </InlineStack>
          </div>
        ),
        reviewer: (
          <InlineStack gap={"200"} wrap={false} blockAlign="center">
            <SkeletonThumbnail size="extraSmall" />
            <div style={{ width: "60px" }}>
              <SkeletonBodyText lines={2} />
            </div>
          </InlineStack>
        ),
        status: (
          <SkeletonThumbnail size="extraSmall" />
        ),
      };
      break;
  }
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const rowMarkupData = Array.from({ length: 10 }, () => gridSkeletonRow);
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(rowMarkupData);

  const rowMarkup = rowMarkupData.map((item, index) => (
    <IndexTable.Row id={item.id} key={index} position={index}>
      {Object.keys(gridSkeletonRow)
        .filter((e) => e !== "id")
        .map((e) => (
          <IndexTable.Cell>{gridSkeletonRow[e]}</IndexTable.Cell>
        ))}
    </IndexTable.Row>
  ));

  return (
    <div className='skeleton_for_checkbox'>
      <IndexTable
        selectable={true}
        resourceName={resourceName}
        itemCount={rowMarkupData.length}
        selectedItemsCount={
          allResourcesSelected ? "All" : selectedResources.length
        }
        onSelectionChange={handleSelectionChange}
        headings={
          heading ?? [
            { title: "Product name" },
            { title: "Etsy Listing ID" },
            { title: "Product Status" },
            { title: "Variants" },
            { title: "Price ($)" },
            { title: "Profile" },
            { title: "Errors" },
            { title: "Actions" },
          ]
        }
      >
        {rowMarkup}
      </IndexTable>
    </div>
  );
}
