import React, { useEffect, useState } from "react";
import { DI } from ".";

// import { ChildrenPropsI } from './@helper';

const NoInternet: React.FC<any> = ({ children }) => {
  const [internetConnection, setInternetConnection] = useState<boolean>(true);

  useEffect(() => {
    const updateInternetConnection = () => {
      setInternetConnection(navigator.onLine);
    };

    // Initial check
    updateInternetConnection();

    // Event listeners
    window.addEventListener("online", updateInternetConnection);
    window.addEventListener("offline", updateInternetConnection);

    // Cleanup function
    return () => {
      window.removeEventListener("online", updateInternetConnection);
      window.removeEventListener("offline", updateInternetConnection);
    };
  }, []);

  return internetConnection ? children : <>No Internet... </>;
};

/**
 * React component that renders its children only if there is an active internet connection.
 *
 * @component
 * @example
 * // Example usage of NoInternet component:
 * <NoInternet>
 *   <App />
 * </NoInternet>
 *
 * @param {Object} props - The props for the NoInternet component.
 * @param {React.ReactNode} props.children - The content to be rendered if there is an active internet connection.
 * @returns {React.ReactNode} Either the provided children or a component indicating no internet connection.
 */
export default DI(NoInternet);
