import {
  Banner,
  Bleed,
  BlockStack,
  Button,
  Card,
  ChoiceList,
  Divider,
  InlineStack,
  Link,
  RadioButton,
  Select,
  SkeletonBodyText,
  Text,
  TextField,
} from "@shopify/polaris";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSettingsContext } from "../SettingsProvider";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";
import { apiUrls, ETSY_SHOP_URL } from "Src/Constant/url";
import { saveEtsyShopDetails } from "Src/Redux/Slices/userSlice";

const GeneralEtsySettings = ({
  request: { POST, GET },
  redux,
  dispatch,
  globalState: { get },
  t,
}: DIProps) => {
  const { localConfig, updateLocalConfig, setIsChange } = useSettingsContext();
  const [reconnect, setReconnect] = useState<string[]>([]);
  const [otherReason, setOtherReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [recreateAllowed, setRecreateAllowed] = useState<{
    [key: string]: boolean;
  }>({
    shipping: false,
    policy: false,
  });
  const [reconnectLoading, setReconnectLoading] = useState(false);
  const refConnectWindow = useRef<Window | null>();

  const handleOtherReasonChange = useCallback(
    (value: string) => setOtherReason(value),
    []
  );

  const handleChange = useCallback(
    (value: string[]) => setReconnect(value),
    []
  );

  const toggleRecreateOptions = (key: string) => {
    setRecreateAllowed((prev) => ({
      ...prev,
      [key]: !prev?.[key],
    }));
  };

  const renderChildren = useCallback(
    (isSelected: boolean) =>
      isSelected && (
        <TextField
          label="Other"
          multiline={4}
          placeholder={t("PROVIDE_A_VALID_REASON_TO_ENABLE_RECONNECTION")}
          labelHidden
          onChange={handleOtherReasonChange}
          value={otherReason}
          autoComplete="off"
        />
      ),
    [handleOtherReasonChange, otherReason]
  );

  const shopStatusOptions = [
    { label: t("DRAFT"), value: "development" },
    { label: t("LIVE"), value: "live" },
  ];

  /**
   * handles message from child window for data exchange
   * @param event message event from child window
   */
  const initRedirect = (event: MessageEvent<any>) => {
    if (
      event.source === refConnectWindow.current &&
      event?.data?.from === "etsy_redirect"
    ) {
      if (event.data?.success === "1") {
        ToastAppBridge(t("ETSY_ACCOUNT_CONNECTED_SUCCESFULLY"));
        setReconnect([]);
        setRecreateAllowed({});
        fetchEtsyAccuntName(true);
      } else {
        ToastAppBridge(t(event?.data?.message), { isError: true });
      }
      refConnectWindow.current?.close();
    }
  };

  /**
   * creates a child popup for connection of etsy account
   */
  const connectEtsyAccount = () => {
    if (refConnectWindow.current) {
      refConnectWindow.current.focus();
    } else {
      const shop = get("shop");
      setLoading(true);
      const reconnectReasons = reconnectChoices
        .filter((choice) => reconnect.includes(choice.value))
        .reduce((prev, curr) => {
          return {
            ...prev,
            [curr.value]: curr.value === "other" ? otherReason : curr.label,
          };
        }, {});
      const recreateTemplates = Object.entries(recreateAllowed)
        .filter(([key, value]) => {
          return value === true;
        })
        .reduce((prev, curr) => {
          return { ...prev, [curr[0]]: curr[0] };
        }, {});
      const payload = {
        authorize: true,
        shop,
        reconnect_etsy: true,
        reconnect_reason: reconnectReasons,
        recreate_templates: recreateTemplates,
      };
      POST(apiUrls.apiConnect, payload)
        .then((res) => {
          if (res?.success) {
            refConnectWindow.current = window.open(
              res?.data?.auth_url,
              "etsy connection",
              "height=700,width=500"
            );
            window.addEventListener("message", initRedirect);
            const interval = setInterval(() => {
              if (refConnectWindow.current) {
                if (refConnectWindow.current.closed) {
                  clearInterval(interval);
                  refConnectWindow.current = null;
                  window.removeEventListener("message", initRedirect);
                }
              }
            }, 1000);
          } else {
            ToastAppBridge(res?.message, { isError: true });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const reconnectChoices = [
    {
      label: t("RECONNECT_ETSY_REASON_1"),
      value: "suspended",
    },
    {
      label: t("RECONNECT_ETSY_REASON_2"),
      value: "wrong_shop",
    },
    {
      label: t("RECONNECT_ETSY_REASON_3"),
      value: "token_error",
    },
    {
      label: t("RECONNECT_ETSY_REASON_4"),
      value: "other",
      renderChildren,
    },
  ];

  const recreateChecks = [
    {
      key: "shipping",
      title: t("DO_YOU_WANT_TRANSFER_SHIPPING_TEMPLATES_TO_NEW"),
      description: t(
        "SELECT_TO_USE_SAME_SHIPPING_TEMPLATE_FROM_PREVIOUS_STORE"
      ),
    },
    {
      key: "policy",
      title: t("DO_YOU_WANT_TRANSFER_POLICY_TEMPLATES_TO_NEW"),
      description: t("SELECT_TO_USE_SAME_POLiCY_TEMPLATE_FROM_PREVIOUS_STORE"),
    },
  ];

  const isReconnnectDisabled =
    reconnect.length === 0 ||
    (reconnect.length === 1 && reconnect[0] === "other" && otherReason === "");

  const fetchEtsyAccuntName = (forced: boolean = false) => {
    if (forced || !redux.userDetails?.etsyShop) {
      setReconnectLoading(true);
      GET(apiUrls.EtsyShopDetails)
        .then((e) => {
          if (e.success) {
            dispatch(saveEtsyShopDetails({ shop: e.data }));
          }
        })
        .finally(() => {
          setReconnectLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchEtsyAccuntName();
  }, []);

  return (
    <Card>
      <BlockStack gap="400">
        <BlockStack gap="200">
          <Text as="h2" variant="headingSm">
            {t("GENERAL_SETTINGS")}
          </Text>
          <Text as="p" variant="bodyMd">
            {t("GENERAL_SETTINGS_DESC")}
          </Text>
        </BlockStack>
        <Bleed marginInlineStart="400" marginInlineEnd="400">
          <Divider />
        </Bleed>
        <BlockStack gap="200">
          <Text variant="bodyMd" as="h3" fontWeight="medium">
            {t("ETSY_SHOP_STATUS")}
          </Text>
          <Text variant="bodyMd" as="p">
            {/* {t("ETSY_SHOP_STATUS_DESC")} */}
            {/* Use Draft mode to keep listings in draft status until your Etsy shop onboarding steps are incomplete. By default, the listing status is active */}
            Use Draft mode to hold listings if your Etsy onboarding is incomplete. By default, the mode is live. 
          </Text>
          <Select
            options={shopStatusOptions}
            value={localConfig?.etsy_store_mode ?? ""}
            label={t("SHOP_STATUS")}
            onChange={(val) => {
              updateLocalConfig({ ...localConfig, etsy_store_mode: val });
              setIsChange(true);
            }}
            labelHidden
            // helpText={t("USE_DRAFT_MODE_TO_TEST_PRODUCTS")}
          />
        </BlockStack>
        <Divider />
        <BlockStack gap="200">
          <InlineStack align="space-between">
            <Text variant="bodyMd" as="h3" fontWeight="medium">
              {t("RECONNECT_ETSY_SHOP")}
              {reconnectLoading ? (
                <SkeletonBodyText lines={1} />
              ) : (
                <>
                  {" ("}
                  <Link
                    onClick={() =>
                      window.open(
                        ETSY_SHOP_URL + redux.userDetails?.etsyShop?.shop_name,
                        "_blank"
                      )
                    }
                  >
                    {redux.userDetails?.etsyShop?.shop_name}
                  </Link>
                  {")"}
                </>
              )}
              {/* {reconnectLoading ? "" : ")"} */}
            </Text>
            <Button
              disabled={isReconnnectDisabled}
              loading={loading}
              onClick={connectEtsyAccount}
            >
              {t("RECONNECT_ETSY")}
            </Button>
          </InlineStack>

          <BlockStack gap="300">
            <ChoiceList
              allowMultiple
              title={t("RECONNECT_ETSY_SHOP_SUBHEAD")}
              choices={reconnectChoices}
              selected={reconnect}
              onChange={handleChange}
            />

            {reconnect.length > 0 && (
              <BlockStack>
                {recreateChecks.map((item) => (
                  <Banner tone="warning" title={item.title}>
                    <Text variant="bodySm" as="p">
                      {item.description}
                    </Text>
                    <InlineStack gap="400" align="start">
                      <RadioButton
                        label={t("YES")}
                        checked={recreateAllowed?.[item.key]}
                        onChange={() => {
                          toggleRecreateOptions(item.key);
                        }}
                      />
                      <RadioButton
                        label={t("NO")}
                        checked={!recreateAllowed?.[item.key]}
                        onChange={() => {
                          toggleRecreateOptions(item.key);
                        }}
                      />
                    </InlineStack>
                  </Banner>
                ))}
              </BlockStack>
            )}
          </BlockStack>
        </BlockStack>
      </BlockStack>
    </Card>
  );
};

export default DI(GeneralEtsySettings);
