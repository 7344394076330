import {
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  InlineGrid,
  InlineStack,
  Select,
  SelectOption,
  Text,
  useBreakpoints,
} from "@shopify/polaris";
import React, { useEffect, useMemo } from "react";
import { DeleteIcon, PlusIcon } from "@shopify/polaris-icons";
import { useProfileContext } from "../ProfileProvider";
import { DIProps, SelectOptionI } from "Src/Interface/@core";
import CustomMultiSelect from "./CustomMultiSelect";
import { VariationAttributeStateI } from "Src/Interface/@Profile";
import { useRoutesContext } from "Src/Component/RoutesProvider";
import { DI } from "Src/core";

const VariationMapping = ({ t }: DIProps) => {
  const {
    shopifyAttributes,
    variationAttributes,
    variationAttributesState,
    updateVariationState,
    updateBasicMapping,
    error,
    removeError,
    basicMapping,
  } = useProfileContext();

  const { routeData } = useRoutesContext();
  const {mdUp, mdDown} = useBreakpoints();

  const selectedShopifyOptions: string[] = useMemo(() => {
    return variationAttributesState.reduce((prev: string[], curr) => {
      return [...prev, ...curr.shopifyValue];
    }, []);
  }, [variationAttributesState]);

  const selectedEtsyOptions: string[] = useMemo(() => {
    return variationAttributesState.reduce((prev: string[], curr) => {
      return [...prev, ...curr.etsyValue];
    }, []);
  }, [variationAttributesState]);

  const etsyVariationOptions: SelectOption[] = useMemo(() => {
    return variationAttributes.map((attr) => ({
      label: attr.display_name,
      value: attr.property_id?.toString(),
      disabled: selectedEtsyOptions.includes(attr.property_id?.toString()),
    }));
  }, [variationAttributes, selectedEtsyOptions]);

  const variationAttrIdIndexMap: { [name: string]: number } =
    variationAttributes.reduce((prev, curr, index) => {
      return { ...prev, [curr.property_id]: index };
    }, {});

  const shopifyVariationOptions: SelectOptionI[] = useMemo(() => {
    return shopifyAttributes
      .map((attr) => ({
        label: attr,
        value: attr,
      }))
      .filter((option) => !selectedShopifyOptions.includes(option.value));
  }, [shopifyAttributes, selectedShopifyOptions]);

  const changeHandlerAttributes = (
    index: number,
    key: "shopifyValue" | "etsyValue" | "scaleValue",
    value: string[]
  ) => {
    const tempVariationState = [...variationAttributesState];
    if (key === "etsyValue") {
      const attrObj = getAttrObjByEtsyValue(value[0]);
      tempVariationState[index]["scales"] = attrObj?.scales;
    }
    if (key === "scaleValue") {
      tempVariationState[index][key] = value[0];
    } else {
      tempVariationState[index][key] = value;
    }
    updateVariationState([...tempVariationState]);
  };

  const addAttribute = () => {
    updateVariationState([
      ...variationAttributesState,
      {
        etsyValue: [],
        shopifyValue: [],
      },
    ]);
  };

  const removeAttribute = (index: number) => {
    const tempVariationState = [...variationAttributesState];
    tempVariationState.splice(index, 1);
    updateVariationState(tempVariationState);
  };

  const getAttrObjByEtsyValue = (id: string) => {
    const index = variationAttrIdIndexMap?.[id];
    return index >= 0 ? variationAttributes[index] : undefined;
  };

  const syncBasicMapping = () => {
    const etsyValues: string[] = [];
    const shopifyValues: string[][] = [];
    const scalesValues: any = {};
    variationAttributesState.forEach((attr) => {
      const attrObj = getAttrObjByEtsyValue(attr.etsyValue[0]);
      const scales = attrObj?.scales;
      const etsyValue = attrObj
        ? `{CedDQuote@#${attrObj?.display_name}CedDQuote@#:CedDQuote@#${attrObj?.property_id}CedDQuote@#}`
        : undefined;
      etsyValues.push(etsyValue ?? "");
      shopifyValues.push(attr.shopifyValue ?? []);
      if (scales?.[0] && attrObj?.property_id) {
        Object.assign(scalesValues, {
          [attrObj?.property_id]: {
            scale_id: scales?.[0]?.scale_id?.toString(),
          },
        });
      }
    });
    updateBasicMapping("variantAttributes_etsy", etsyValues);
    updateBasicMapping("variantAttributes_shopify", shopifyValues);
    updateBasicMapping("option_name_selected", scalesValues);
  };

  // formats edit data as required by this component for user to edit its values
  const syncEditData = () => {
    const variantAttributesArr: string[][] = Object.entries(
      basicMapping.variantAttributesEdit
    );

    if (
      (variationAttributes?.length > 0 || shopifyAttributes?.length > 0) &&
      variantAttributesArr?.length > 0
    ) {
      const variationAttrTemp: VariationAttributeStateI[] = [];

      const etsyAttrs = new Set<string>();
      const shopifyAttrsObj: { [key: string]: string[] } = {};

      variantAttributesArr.forEach(([shopifyAttr, propertyId]) => {
        etsyAttrs.add(propertyId);
        if (shopifyAttrsObj?.[propertyId]) {
          shopifyAttrsObj[propertyId].push(shopifyAttr);
        } else {
          Object.assign(shopifyAttrsObj, { [propertyId]: [shopifyAttr] });
        }
      });

      Array.from(etsyAttrs).forEach((attr) => {
        const attrObj = getAttrObjByEtsyValue(attr);
        const scales = attrObj?.scales;
        variationAttrTemp.push({
          etsyValue: [attr],
          shopifyValue: shopifyAttrsObj[attr],
          scaleValue:
            basicMapping.option_name_selected_edit?.[attr]?.scale_id ??
            undefined,
          scales,
        });
      });
      updateVariationState(variationAttrTemp);
    }
  };

  useEffect(() => {
    if (routeData.data.type !== "edit" && routeData.data.type !== "clone") {
      const attrData = variationAttributes
        .map((attr) => {
          const shopifyValue = shopifyAttributes.filter((shopifyAttr) =>
            shopifyAttr.toLowerCase().includes(attr.display_name.toLowerCase())
          );
          return {
            shopifyValue: shopifyValue,
            etsyValue: attr?.property_id ? [attr?.property_id] : [],
            scales: attr?.scales,
            scaleValue: attr?.scales?.[0]?.scale_id?.toString(),
          };
        })
        .filter((attr) => attr.shopifyValue.length > 0);
      updateVariationState(attrData);
    }
  }, [variationAttributes, shopifyAttributes]);

  useEffect(() => {
    if (["edit", "clone"].includes(routeData.data.type)) {
      syncEditData();
    }
  }, [
    basicMapping.variantAttributesEdit,
    basicMapping.option_name_selected_edit,
    variationAttributes,
    shopifyAttributes,
  ]);

  useEffect(() => {
    syncBasicMapping();
  }, [variationAttributesState, variationAttributes]);

  return (
    <BlockStack gap={"200"}>
      <BlockStack gap={"100"}>
        <Text as="h6" variant="bodyMd">
          {t("VARIATION_MAPPING")}
        </Text>
        <Text as="p" variant="bodyMd" tone="subdued">
          {t("VARIATION_MAPPING_DESC")}
        </Text>
      </BlockStack>
      <Card>
        <BlockStack gap="200">
          {variationAttributesState.map((attr, index) => {
            return (
              <>
              <InlineStack gap="200" wrap={mdUp ? false : true}>
                <Box width="100%">
                  <InlineGrid columns={mdUp ? 2 : 1} gap="200">
                    <div className="manage_tags_scroll">
                      <CustomMultiSelect
                        id={`variation_mapping_${index}_shopify_value`}
                        label={
                          index === 0 ? (
                            <Text
                              as="span"
                              fontWeight="medium"
                              variant="bodyMd"
                            >
                              {t("SHOPIFY_ATTRIBUTES")}
                            </Text>
                          ) : mdDown ? (
                            <Text
                            as="span"
                            fontWeight="medium"
                            variant="bodyMd"
                          >
                            {t("SHOPIFY_ATTRIBUTES")}
                          </Text>
                          ) : ''
                        }
                        placeholder="Search"
                        options={shopifyVariationOptions}
                        value={attr.shopifyValue}
                        onChange={(val: string[]) => {
                          removeError(
                            `variation_mapping_${index}_shopify_value`
                          );
                          changeHandlerAttributes(index, "shopifyValue", val);
                        }}
                        error={
                          error?.[`variation_mapping_${index}_shopify_value`]
                        }
                        noCustomValue
                      />
                    </div>
                    <InlineGrid columns={attr?.scales ? 2 : 1} gap="200">
                      <div className="manage_tags_scroll">
                        <Select
                          id={`variation_mapping_${index}_etsy_value`}
                          placeholder="Search"
                          disabled={routeData.data.type === "view"}
                          label={
                            index === 0 ? (
                              <Text
                                as="span"
                                fontWeight="medium"
                                variant="bodyMd"
                              >
                                {t("ETSY_ATTRIBUTES")}
                              </Text>
                            ) : mdDown ? (
                              <Text
                              as="span"
                              fontWeight="medium"
                              variant="bodyMd"
                            >
                              {t("ETSY_ATTRIBUTES")}
                            </Text>
                            ) : ""
                          }
                          options={etsyVariationOptions}
                          value={attr.etsyValue?.[0]}
                          onChange={(val: string) => {
                            removeError(
                              `variation_mapping_${index}_etsy_value`
                            );
                            changeHandlerAttributes(index, "etsyValue", [val]);
                          }}
                          error={
                            error?.[`variation_mapping_${index}_etsy_value`]
                          }
                        />
                      </div>
                      {attr.scales ? (
                        <div
                          style={{
                            paddingBlockStart: index === 0 && mdUp ? "24px" : mdDown ? "24px" : "",
                          }}
                        >
                          <Select
                            id={`variation_mapping_${index}_scale_value`}
                            label=""
                            labelHidden
                            value={attr?.scaleValue ?? ""}
                            options={attr.scales.map((scale) => ({
                              label: scale.display_name,
                              value: scale.scale_id?.toString(),
                            }))}
                            disabled={routeData.data.type === "view"}
                            onChange={(val) => {
                              changeHandlerAttributes(index, "scaleValue", [
                                val,
                              ]);
                            }}
                          />
                        </div>
                      ) : null}
                    </InlineGrid>
                  </InlineGrid>
               
                </Box>
                <div
                  className="delete_btn_icon"
                  style={{ paddingBlockStart: index === 0 && mdUp ? "30px" : "5px" }}
                >
                  <Button
                    icon={mdUp ? DeleteIcon : ''}
                    children={mdUp ? "" :  'Delete'}
                    variant="plain"
                    tone="critical"
                    disabled={
                      routeData.data.type === "view" ||
                      variationAttributesState.length === 1
                    }
                    onClick={() => {
                      removeAttribute(index);
                    }}
                  />
                </div>
              </InlineStack>
              {mdDown  && index !== variationAttributesState.length - 1 && (<Box paddingBlockStart='200' paddingBlockEnd="200"><Divider/></Box>)}
              </>
            );
          })}
          {routeData.data.type !== "view" && (
            <BlockStack gap="300">
              <Divider />
              <InlineStack align="end">
                <Button
                  icon={PlusIcon}
                  disabled={
                    shopifyVariationOptions.length === 0 ||
                    shopifyAttributes.length === variationAttributesState.length
                  }
                  onClick={addAttribute}
                >
                  {t("ADD_ATTRIBUTE")}
                </Button>
              </InlineStack>
            </BlockStack>
          )}
        </BlockStack>
      </Card>
    </BlockStack>
  );
};

export default DI(VariationMapping);
