export const FillChecked = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill="#1A1A1A" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.8536 5.31319C12.0488 5.50846 12.0488 5.82504 11.8536 6.0203L7.52022 10.3536C7.32496 10.5489 7.00838 10.5489 6.81311 10.3536L4.64645 8.18697C4.45118 7.99171 4.45118 7.67512 4.64645 7.47986C4.84171 7.2846 5.15829 7.2846 5.35355 7.47986L7.16667 9.29297L11.1464 5.31319C11.3417 5.11793 11.6583 5.11793 11.8536 5.31319Z"
      fill="#E3E3E3"
    />
  </svg>
);
export const InPorgress = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8"
      cy="10"
      r="7.33333"
      fill="#FAFBFB"
      stroke="#1A1A1A"
      strokeWidth="1.33333"
      stroke-dasharray="2.67 2.67"
    />
  </svg>
);
export const Star = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1281 4.12354C10.6753 3.17464 9.32461 3.17464 8.87182 4.12354L7.48228 7.03555L4.28339 7.45723C3.24101 7.59463 2.82362 8.87922 3.58616 9.60308L5.92626 11.8245L5.33879 14.9971C5.14735 16.0309 6.24009 16.8248 7.16416 16.3233L9.99996 14.7842L12.8358 16.3233C13.7598 16.8248 14.8526 16.0309 14.6611 14.9971L14.0737 11.8245L16.4138 9.60308C17.1763 8.87922 16.7589 7.59463 15.7165 7.45723L12.5176 7.03555L11.1281 4.12354Z"
      fill="#FFB800"
    />
  </svg>
);
export const success = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <circle cx="10" cy="10" r="8" fill="#136F45" fillOpacity="0.3" />
    <circle cx="10" cy="10" r="4" fill="#136F45" />
  </svg>
);

export const error = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <circle cx="10" cy="10" r="8" fill="#E51C00" fillOpacity="0.3" />
    <circle cx="10" cy="10" r="4" fill="#E51C00" />
  </svg>
);
export const warning = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <circle cx="10" cy="10" r="8" fill="#FFB800" fillOpacity="0.3" />
    <circle cx="10" cy="10" r="4" fill="#FFB800" />
  </svg>
);

export const Tick = (
  <svg width="100" height="101" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="5" y="5.5" width="90" height="90" rx="45" fill="#D7EAE2" />
  <rect x="5" y="5.5" width="90" height="90" rx="45" stroke="#EEF6F3" strokeWidth="10" />
  <path d="M66.6668 38L43.7502 60.9167L33.3335 50.5" stroke="#007F5F" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
</svg>
)