import React, { ComponentType } from "react";
import * as _Services from "./_Services";
import ErrorBoundary from "./ErrorBoundry";
import { useDispatch, useSelector } from "react-redux";
import { DIProps } from "Src/Interface/@core";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { AppBridgeState, ClientApplication } from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
// Define the type for the additional props you want to inject

// Define a type that combines the original props and the additional props
type PropsWithExtra<P> = P & DIProps;

const {
  GlobalState: { set, get, remove, reset },
  request: { GET, DELETE, PATCH, POST, PUT },
} = _Services;
const DependencyInjection = <P extends object>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof DIProps>> => {
  const ComponentWrapper = (props: Omit<P, keyof DIProps>): JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const AppBridgeRouting = (
      app: ClientApplication<AppBridgeState> | null,
      route: string
    ) => {
      const isEmbeddedApp = get()("isEmbed") === "true";
      if (isEmbeddedApp) {
        if (app) {
          window.location.href = route;
          // const redirect = Redirect.create(app);
          // redirect.dispatch(Redirect.Action.APP, route);
        }
      } else {
        navigate(route);
      }
    };
    const AppBridgeExternalRoute = (
      app: ClientApplication<AppBridgeState> | null,
      route: string
    ) => {
      const isEmbeddedApp = get()("isEmbed") === "true";
      if (isEmbeddedApp) {
        if (app) {
          const redirect = Redirect.create(app);
          redirect.dispatch(Redirect.Action.REMOTE, route);
        }
      } else {
        window.location.replace(route);
      }
    };
    const globalState = {
      set: set(),
      get: get(),
      reset: reset(),
      remove: remove(),
    };

    const request = {
      GET: GET(),
      DELETE: DELETE(),
      PATCH: PATCH(),
      POST: POST(),
      PUT: PUT(),
    };

    const DIProps = {
      globalState,
      request,
      redux: useSelector((state: any) => state),
      dispatch: dispatch,
      t,
      AppBridgeRouting,
      AppBridgeExternalRoute,
      location,
      navigate,
    };
    const newProps = {
      ...(props as P),
      ...DIProps,
    };
    return (
      <ErrorBoundary fallback={<>ERROR</>}>
        <Component {...newProps} />
      </ErrorBoundary>
    );
  };
  return ComponentWrapper;
};

export default DependencyInjection;
export { DependencyInjection as DI };
