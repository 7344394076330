import React from 'react'
import SettingSkeleton from './SettingSkeleton'
import { Box, InlineStack, Page, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris'

const WithPageHeaderSettingSkeleton = () => {
    return (
        <div>
            <Box borderBlockEndWidth="025" borderColor="border" paddingBlockEnd="400" paddingBlockStart={'100'}>
                <div className="custom_layout_width">
                    <div className='skeleton_page_header_spacing'>
                        <Page fullWidth>
                            <InlineStack align='space-between' blockAlign='center'>
                                <div style={{ "width": "150px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
                                <InlineStack gap={"200"}>
                                    <div className='skeleton-height-32px' style={{ "width": "1px" }}>
                                        <SkeletonDisplayText size='small' />
                                    </div>
                                </InlineStack>
                            </InlineStack>
                        </Page>
                    </div>
                </div>
            </Box>
            <SettingSkeleton />
        </div>
    )
}

export default WithPageHeaderSettingSkeleton
