import React, { useEffect, useState } from "react";
import { NotificationIcon } from "@shopify/polaris-icons";
import {
  BlockStack,
  Box,
  Button,
  Divider,
  Icon,
  InlineStack,
  Popover,
  Text,
  useBreakpoints,
} from "@shopify/polaris";
import "./notification.css";
import NotificationsTab from "../Activities/components/NotificationsTab";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";
import { GetNotificationAnnouncementData } from "../Activities/Helper";
import { useShopify } from "Src/ShopifyAppBridgeProvider";
import { toggleNewNotification } from "Src/Redux/Slices/activity";
const Activator = ({
  request: { GET },
  dispatch,
  redux,
  t,
  AppBridgeRouting,
}: DIProps) => {
  const { smUp } = useBreakpoints();
  const [selectedTab, setSelectedTab] = useState(0);
  const [filter, setFilter] = useState(false);
  const notificationCount = 0;

  const app = useShopify();
  useEffect(() => {
    GetNotificationAnnouncementData(GET, dispatch, redux, true);
  }, []);
  return (
    <div className="app_notification_action">
      <Popover
        active={filter}
        activator={
          <div className="notification-btn">
            <Button
              onClick={() => {
                document.body.click();
                setFilter(!filter);
                dispatch(toggleNewNotification(false));
              }}
              icon={<Icon source={NotificationIcon} tone="base" />}
              // children={"4"}
            />
            {redux.activity.newNotificationAvailable && (
              <div
                className={`notification-count ${
                  notificationCount > 0 ? "with-count" : "without-count"
                }`}
              >
                {notificationCount > 0 && notificationCount}
              </div>
            )}
          </div>
        }
        autofocusTarget="first-node"
        onClose={() => setFilter(false)}
        sectioned
        preferredAlignment="right"
      >
        <Box
          paddingBlock="200"
          paddingInline="200"
          minWidth={smUp ? "350px" : "300px"}
          maxWidth={smUp ? "350px" : "300px"}
        >
          <BlockStack gap="400">
            <InlineStack align="space-between" blockAlign="center">
              <Text variant="headingLg" as="h2">
                {t("NOTIFICATIONS")}
              </Text>
            </InlineStack>
            <BlockStack gap="300">
              <div className="activity_popover_content_scroll">
                <NotificationsTab
                  popover={true}
                  AllActivites={redux.activity.activityData}
                  AllAnnouncement={redux.activity.announcementData}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
                {selectedTab !== 0 && <Divider />}
                {selectedTab !== 0 && (
                  <InlineStack align="end">
                    <Button
                      variant="plain"
                      onClick={() => AppBridgeRouting(app, "/panel/activity")}
                    >
                      {t("VIEW_ALL")}
                    </Button>
                  </InlineStack>
                )}
              </div>
            </BlockStack>
          </BlockStack>
        </Box>
      </Popover>
    </div>
  );
};

export default DI(Activator);
