import {
  BlockStack,
  Card,
  FormLayout,
  Icon,
  Text,
  Select,
  TextField,
  Divider,
  InlineStack,
  Button,
  useBreakpoints,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { PlusIcon } from "@shopify/polaris-icons";
import { DI } from "Src/core";
import { shipping_carrier_by_country } from "Src/Constant/helpData";
import { DIProps } from "Src/Interface/@core";
import { StateI } from "../Shipping";

interface DeliveryUi extends DIProps {
  shipping_upgrade: any;
  error: string[];
  setError: (e: string[]) => void;
  state: StateI;
  getdata: (e: any) => void;
  setDelete_upgrade_entry: (e: string) => void;
  delete_upgrade_entry: string;
}
const DeliveryUpgrade = ({
  shipping_upgrade,
  state,
  getdata,
  setDelete_upgrade_entry,
  delete_upgrade_entry,
  error,
  setError,
  redux: { userDetails },
  t
}: DeliveryUi) => {
  const {mdUp} = useBreakpoints();
  const ReqMsg = "This is Required Field";
  const [shipping_options, setShipping_options] = useState<
    { label: string; value: string }[]
  >([]);
  const [rows, setRows] = useState<any>([]);
  const addRow = () => {
    const id = Date.now() + Math.random();
    setRows([
      ...rows,
      { id: id, type: "0", shipping_carrier_id: 0, upgrade_name: "1 Day" },
    ]);
  };
  const getShippingCarrier = (type: string) => {
    if (!shipping_carrier_by_country?.[state.origin_country_id]) {
      return [{ label: "Other", value: 0 }];
    }

    return [
      { label: "Other", value: 0 },
      ...shipping_carrier_by_country?.[state.origin_country_id]
        ?.map((SC: any) => ({
          ...SC,
          label: SC.name,
          value: SC.shipping_carrier_id,
        }))
        .filter((e: any) => {
          const filterType =
            type === "0" ? "domestic_classes" : "international_classes";
          return e[filterType].length > 0;
        }),
    ];
  };
  useEffect(() => {
    const tepRows: any[] = [];
    shipping_upgrade?.map((e: any) => {
      const ship_upg = {
        id: e.id,
        merchant_id: e.merchant_id,
        shipping_profile_id: e.shipping_profile_id,
        upgrade_id: e.upgrade_id,
        upgrade_name: e.upgrade_name,
        type: e.type.toString(),
        rank: e.rank,
        price: e.price,
        secondary_price: e.secondary_price,
        shipping_carrier_id: e.shipping_carrier_id,
        mail_class: e.mail_class,
        min_delivery_days: e.min_delivery_days?.toString(),
        max_delivery_days: e.max_delivery_days?.toString(),
      };
      tepRows.push(ship_upg);
    });
    setRows(tepRows);
  }, [state.origin_country_id]);
  const deleteRow = (id: any, upgrade_id: any) => {
    const filteredRow = rows.filter((item: any) => item.id !== id);
    let add = upgrade_id;

    if (delete_upgrade_entry !== "") {
      add = delete_upgrade_entry + "," + add;
    }
    upgrade_id && setDelete_upgrade_entry(add);
    setRows(filteredRow);
  };
  useEffect(() => {
    getdata({ code: "delivery", data: rows });
  }, [rows]);
  const deliveryUpgrade = (index: number) => {
    const curr_row = rows[index];
    const getMinMaxDelDaysOptions = (code: 0 | 1) => {
      let option: { label: string; value: string; disabled: boolean }[] = [];
      for (let i = 0; i < 45; i++) {
        const isDisable = () => {
          if (code === 0 && parseInt(curr_row.max_delivery_days) - 1 < i) {
            return true;
          }
          if (code === 1 && parseInt(curr_row.min_delivery_days) - 1 > i) {
            return true;
          }
          return false;
        };
        option.push({
          label: (i + 1).toString(),
          value: (i + 1).toString(),
          disabled: isDisable(),
        });
      }
      return option;
    };
    const getMailOptions = () => {
      const type =
        curr_row.type === "0" ? "domestic_classes" : "international_classes";
      const isExist: any = getShippingCarrier(curr_row.type).find(
        (e) => e.value === curr_row.shipping_carrier_id
      );
      if (isExist) {
        return isExist[type].map((e: any) => ({
          label: e.name,
          value: e.mail_class_key,
        }));
      }
      return [];
    };
    return (
      <FormLayout>
        <FormLayout.Group condensed={mdUp ? true : false}>
          <Select
            requiredIndicator
            placeholder={t("SELECT")}
            label={t("DESTINATION")}
            options={[
              { label: t("DOMESTIC"), value: "0" },
              { label: t("INTERNATIONAL"), value: "1" },
            ]}
            value={curr_row.type}
            onChange={(e) => {
              const tempRows = structuredClone(rows);
              tempRows[index].type = e;
              setRows(tempRows);
            }}
          />
          <Select
            placeholder={t("SELECT")}
            label={t("UPGRADE")}
            options={[
              { label: t("1_DAY"), value: "1 Day" },
              { label: t("EXPRESS"), value: "Express" },
              { label: t("ECONOMY"), value: "Economy" },
            ]}
            value={curr_row.upgrade_name}
            onChange={(e) => {
              const tempRows = structuredClone(rows);
              tempRows[index].upgrade_name = e;
              setRows(tempRows);
            }}
          />
          <Select
            requiredIndicator
            placeholder={t("SELECT")}
            label={t("SHIPPING_CARRIER")}
            options={getShippingCarrier(curr_row.type)}
            value={curr_row.shipping_carrier_id}
            onChange={(e) => {
              const tempRows = structuredClone(rows);
              tempRows[index].shipping_carrier_id = parseInt(e);
              tempRows[index].mail_class = null;
              setRows(tempRows);
            }}
          />
          {curr_row.shipping_carrier_id === 0 && (
            <Select
              error={
                error.includes("min_delivery_days-" + index)
                  ? ReqMsg
                  : undefined
              }
              id={"min_delivery_days-" + index}
              requiredIndicator
              placeholder={t("SELECT")}
              label={t("MIN_DELIVERY_DAYS")}
              options={getMinMaxDelDaysOptions(0)}
              value={curr_row.min_delivery_days}
              onChange={(e) => {
                const tempRows = structuredClone(rows);
                tempRows[index].min_delivery_days = e;
                error.includes("min_delivery_days-" + index) &&
                  setError([
                    ...error.filter((e) => e !== "min_delivery_days-" + index),
                  ]);
                setRows(tempRows);
              }}
            />
          )}
          {curr_row.shipping_carrier_id === 0 && (
            <Select
              error={
                error.includes("max_delivery_days-" + index)
                  ? ReqMsg
                  : undefined
              }
              id={"max_delivery_days-" + index}
              requiredIndicator
              placeholder={t("SELECT")}
              label={t("MAX_DELIVERY_DAYS")}
              options={getMinMaxDelDaysOptions(1)}
              value={curr_row.max_delivery_days}
              onChange={(e) => {
                const tempRows = structuredClone(rows);
                tempRows[index].max_delivery_days = e;
                error.includes("max_delivery_days-" + index) &&
                  setError([
                    ...error.filter((e) => e !== "max_delivery_days-" + index),
                  ]);
                setRows(tempRows);
              }}
            />
          )}
        </FormLayout.Group>
        <FormLayout.Group condensed={mdUp ? true : false}>
          {curr_row.shipping_carrier_id !== 0 && (
            <Select
              error={error.includes("mail_class-" + index) ? ReqMsg : undefined}
              id={"mail_class-" + index}
              requiredIndicator
              placeholder={t("SELECT")}
              label={t("MAIL_CLASS")}
              options={getMailOptions()}
              value={curr_row.mail_class}
              onChange={(e) => {
                const tempRows = structuredClone(rows);
                tempRows[index].mail_class = e;
                error.includes("mail_class-" + index) &&
                  setError([
                    ...error.filter((e) => e !== "mail_class-" + index),
                  ]);
                setRows(tempRows);
              }}
            />
          )}
          <TextField
            error={error.includes("price-" + index) ? ReqMsg : undefined}
            id={"price-" + index}
            requiredIndicator
            placeholder="0.00"
            label={t("ONE_ITEM")}
            type="number"
            value={curr_row.price}
            onChange={(e) => {
              const numberRegex = /^[0-9]+(\.[0-9]+)?$/;
              if (e !== "" && !numberRegex.test(e)) {
                return;
              }
              const tempRows = structuredClone(rows);
              tempRows[index].price = e;
              error.includes("price-" + index) &&
                setError([...error.filter((e) => e !== "price-" + index)]);
              setRows(tempRows);
            }}
            prefix={userDetails?.shopifyShop?.currency}
            autoComplete="off"
          />
          <TextField
            error={
              error.includes("secondary_price-" + index)
                ? ["", undefined].includes(curr_row.secondary_price)
                  ? ReqMsg
                  : t("ADDITIONAL_ITEM_CANT_BE_MORE_THAN_ONE")
                : undefined
            }
            id={"secondary_price-" + index}
            placeholder="0.00"
            requiredIndicator
            label={t("ADDITIONAL_ITEM")}
            type="number"
            value={curr_row.secondary_price}
            onChange={(e) => {
              const numberRegex = /^[0-9]+(\.[0-9]+)?$/;
              if (e !== "" && !numberRegex.test(e)) {
                return;
              }
              const tempRows = structuredClone(rows);
              tempRows[index].secondary_price = e;
              error.includes("secondary_price-" + index) &&
                setError([
                  ...error.filter((e) => e !== "secondary_price-" + index),
                ]);
              setRows(tempRows);
            }}
            prefix={userDetails?.shopifyShop?.currency}
            autoComplete="off"
          />
        </FormLayout.Group>
      </FormLayout>
    );
  };
  return (
    <Card>
      <BlockStack gap={"300"}>
        <BlockStack gap={"300"}>
          <Text as="h6" variant="headingSm" fontWeight="medium">
            {t("DELIVERY_UPGRADES")}{" "}
            <Text
              as="span"
              variant="headingSm"
              fontWeight="medium"
              tone="subdued"
            >
              ({t("OPTIONAL")})
            </Text>
          </Text>
        </BlockStack>

        {rows.map((row: any, index: any) => (
          <BlockStack key={"Del" + index} gap={"300"}>
            <Divider />
            {deliveryUpgrade(index)}
            <InlineStack align="end">
              <Button
                variant="plain"
                tone="critical"
                onClick={() => deleteRow(row.id, row.upgrade_id)}
              >
                {t("DELETE_DELIVERY_UPGRADE")}
              </Button>
            </InlineStack>
          </BlockStack>
        ))}
        <Divider />
        <InlineStack align="start">
          <Button
            onClick={addRow}
            icon={<Icon source={PlusIcon} tone="base" />}
          >
            {t("ADD_DELIVERY_UPGRADES")}
          </Button>
        </InlineStack>
      </BlockStack>
    </Card>
  );
};

export default DI(DeliveryUpgrade);
