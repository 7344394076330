import React, { useRef, useState } from "react";

/**
 * custom hook to handle debouncing
 * @param onDebounceComplete change handler to be called after delay expires
 * @param delay count of milliseconds delay
 * @returns changehandler and latest value
 */
const useDebounce = (
  onDebounceComplete: (val: string) => void,
  delay: number,
  intialValue?: string
) => {
  const [debouncedValue, setDebouncedValue] = useState<string>(
    intialValue ?? ""
  );
  const timeOutRef = useRef<any>();

  const changeDebouncedValue = (val: string, updateDebounce = true) => {
    setDebouncedValue(val);
    if (updateDebounce) {
      clearTimeout(timeOutRef.current);
      timeOutRef.current = setTimeout(() => {
        onDebounceComplete(val);
      }, delay);
    }
  };

  return { debouncedValue, changeDebouncedValue };
};

export default useDebounce;
