import React, { useEffect, useRef, useState } from "react";
import { DI } from "../core";
import { useShopify } from "../ShopifyAppBridgeProvider";
import { DIProps } from "../Interface/@core";
import AppRouter from "Src/routes/Router";
import { BlockStack, Box, Link, Page, Text } from "@shopify/polaris";
import { apiUrls } from "Src/Constant/url";
import {
  saveShopifyShopDetails,
  saveUserDetails,
} from "Src/Redux/Slices/userSlice";
import { ToastAppBridge } from "./HelperComponents/Toast";
import { webSocket } from "./HelperComponents/Websocket";
import BetaNotification from "./BetaNotification";

const { getSteps, getWebsocketToken } = apiUrls;
const Panel = (_props: DIProps) => {
  const {
    t,
    AppBridgeRouting,
    location,
    redux: { userDetails },
    request: { GET },
    dispatch,
  } = _props;
  const { app } = useShopify();
  const getIsOnboarded = async () => {
    if (!userDetails.isOnboarded) {
      const res = await GET(getSteps);
      if (res?.success && res?.data?.current_step) {
        if (res?.data?.status === "complete") {
          return { onboarded: true };
        }
      } else {
        ToastAppBridge(res?.message, { isError: true });
        return { onboarded: false };
      }
    }
    return { onboarded: userDetails.isOnboarded };
  };
  const getWebSocketTokenAction = () => {
    GET(getWebsocketToken).then((e) => {
      webSocket(e.data, _props);
    });
  };

  const checkStep = async () => {
    if (
      ![
        "/payment/confirmpayment",
        "etsy-redirect",
        "/payment/confirm-order-addon-payment",
      ].includes(location.pathname)
    ) {
      const { onboarded }: any = await getIsOnboarded();
      if (userDetails.isOnboarded !== onboarded) {
        dispatch(saveUserDetails({ isOnboarded: onboarded }));
      }
      if (onboarded === false && !["/onboarding"].includes(location.pathname)) {
        AppBridgeRouting(app, "/onboarding");
      } else if (
        onboarded &&
        (location.pathname === "/onboarding" || location.pathname === "/")
      ) {
        AppBridgeRouting(app, "/panel/overview");
      }
    }
  };
  const getShopifyDeltails = () => {
    GET(apiUrls.shopifyShopDetail).then((res) => {
      const { success, data } = res;
      if (success) {
        dispatch(saveShopifyShopDetails({ shop: data }));
      }
    });
  };
  useEffect(() => {
    getWebSocketTokenAction();
    getShopifyDeltails();
    checkStep();
    //eslint-disable-next-line
  }, []);
  // Footer height
  const elementRef = useRef<HTMLDivElement | null>(null); // Ref with explicit typing
  const [footerHeight, setHeight] = useState<number>(0);

  useEffect(() => {
    if (elementRef.current) {
      setHeight(elementRef.current.offsetHeight); // TypeScript now knows this is an HTMLDivElement
    }
  }, []);

  const newStyle = {
    minHeight : `calc(100vh - ${footerHeight}px)`
  }
  return (
    <div>
      <Box>
        <div className="content_header" style={newStyle}>
          {/* <BetaNotification /> */}
          <AppRouter />
        </div>
        <div className="no_embeded_footer" ref={elementRef}>
          <Page fullWidth>
            <BlockStack gap={"100"} align="start">
              <Text as="p" variant="bodyMd" tone="subdued">
                {t("COPYRIGHT")}{" | "}
                <Link
                  onClick={() => {
                    window.open(
                      "https://cedcommerce.com/privacy-policy",
                      "_blank"
                    );
                  }}
                >
                  {t("PRIVACY_POLICY")}
                </Link>{" | "}
                <Link
                  onClick={() => {
                    window.open(
                      "https://calendly.com/cedcommerceetsyintegration/query-for-cedcommerce-etsy-integration-app",
                      "_blank"
                    );
                  }}
                >
                  {t("GET_SUPPORT")}
                </Link>
              </Text>
              <Text as="p" variant="bodyMd" tone="subdued">
                {t("ETSY_FOOTER")}
              </Text>
            </BlockStack>
          </Page>
        </div>
      </Box>
    </div>
  );
};

export default DI(Panel);
