import { BlockStack, Box, IndexTable, InlineStack, SkeletonBodyText, SkeletonDisplayText, useBreakpoints, useIndexResourceState } from '@shopify/polaris'
import React from 'react'
// import './skeleton.css'

export default function OrderGridSkeleton() {
    const {mdUp} =  useBreakpoints();

    const gridSkeletonRow = {
        id: '1',
        receiptId: <div className='failed-order-skelton' style={{ 'width': '80px' }}><SkeletonBodyText lines={1} /></div>,
        OrderName: <div className='failed-order-skelton' style={{ 'width': '150px' }}><SkeletonBodyText lines={1} /></div>,
        country: <div className='failed-order-skelton' style={{ 'width': '60px' }}><SkeletonBodyText lines={1} /></div>,
        CustomerName: <div className='failed-order-skelton' style={{ 'width': '100px' }}><SkeletonBodyText lines={1} /></div>,
        orderValue: <div className='failed-order-skelton' style={{ 'width': '70px' }}><SkeletonBodyText lines={1} /></div>,
        status: <div className='failed-order-skelton' style={{ 'width': '50px' }}><SkeletonBodyText lines={1} /></div>,
        createdAt: <div className='failed-order-skelton' style={{ 'width': '92px' }}><SkeletonBodyText lines={1} /></div>,
        digitalOrder: <div className='failed-order-skelton' style={{ 'width': '70px' }}><SkeletonBodyText lines={1} /></div>,
        isGiftOrder: <div className='failed-order-skelton' style={{ 'width': '92px' }}><SkeletonBodyText lines={1} /></div>,
        Action: <div style={{ 'width': '28px' }}>
            <SkeletonDisplayText size="small" />
        </div>,
    }

    const resourceName = {
        singular: 'order',
        plural: 'orders',
    };

    const rowMarkupData = Array.from({ length: 10 }, () => gridSkeletonRow);
    const { selectedResources, allResourcesSelected, handleSelectionChange } =
        useIndexResourceState(rowMarkupData);

    const rowMarkup = rowMarkupData.map((item, index) => (
        <IndexTable.Row
            id={item.id}
            key={index}
            position={index}
        >
            <IndexTable.Cell>
                {item.receiptId}
            </IndexTable.Cell>
            <IndexTable.Cell>
                {item.OrderName}
            </IndexTable.Cell>
            <IndexTable.Cell>{item.country}</IndexTable.Cell>
            <IndexTable.Cell>{item.CustomerName}</IndexTable.Cell>
            <IndexTable.Cell>{item.orderValue}</IndexTable.Cell>
            <IndexTable.Cell>{item.status}</IndexTable.Cell>
            <IndexTable.Cell>{item.createdAt}</IndexTable.Cell>
            <IndexTable.Cell>{item.digitalOrder}</IndexTable.Cell>
            <IndexTable.Cell>{item.isGiftOrder}</IndexTable.Cell>
            <IndexTable.Cell>{item.Action}</IndexTable.Cell>
        </IndexTable.Row>
    ),
    );



  const rowMarkupMobile = rowMarkupData.map((item, index) => (
    <Box padding='300' id={item.id} key={index} borderBlockEndWidth={'025'} borderColor="border">
            
                <InlineStack wrap={false} gap='200' align="space-between" blockAlign="start">
                    <InlineStack wrap={false} gap='200' blockAlign="start">
                    <div style={{ 'width': '20px' }} className='skeleton-height-20'>
              <SkeletonBodyText lines={1} />
            </div>

                        <BlockStack gap='300'>
                            <InlineStack gap='200' blockAlign="start" align="start" wrap={false}>
                                <div className='failed-order-skelton skeleton-height-22' style={{ 'width': '170px' }}><SkeletonBodyText lines={1} /></div>
                                <div className='failed-order-skelton skeleton-height-22' style={{ 'width': '50px' }}><SkeletonBodyText lines={1} /></div>
                            </InlineStack>

                            <BlockStack gap='200'>
                            <div className='failed-order-skelton custom-height_skel' style={{ 'width': '100px' }}><SkeletonBodyText lines={1} /></div>
                            <InlineStack gap='200' blockAlign="start" align="start" wrap={false}>
                                <div className='failed-order-skelton skeleton-height-25' style={{ 'width': '70px' }}><SkeletonBodyText lines={1} /></div>
                                <div className='failed-order-skelton skeleton-height-25' style={{ 'width': '70px' }}><SkeletonBodyText lines={1} /></div>
                            </InlineStack>
                            </BlockStack>

                            <BlockStack gap='200'>
                            <div className='failed-order-skelton custom-height_skel' style={{ 'width': '100px' }}><SkeletonBodyText lines={1} /></div>
                            <div className='failed-order-skelton custom-height_skel' style={{ 'width': '170px' }}><SkeletonBodyText lines={1} /></div>

                            <div className='failed-order-skelton custom-height_skel' style={{ 'width': '180px' }}><SkeletonBodyText lines={1} /></div>
                            </BlockStack>

                        </BlockStack>
                    </InlineStack>

                    <div style={{ 'width': '32px' }} className='skeleton-height-32'>
                    <SkeletonBodyText lines={1} />
                    </div>
                </InlineStack>
            </Box>
    ),
    );


    return (
     mdUp ?   <div className='skeleton_for_checkbox'>
            <IndexTable

                selectable
                resourceName={resourceName}
                itemCount={rowMarkupData.length}
                selectedItemsCount={
                    allResourcesSelected ? 'All' : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
                headings={[
                    { title: "Etsy Order ID" },
                    { title: "Order name (Shopify)" },
                    { title: "Country" },
                    { title: "Customer" },
                    { title: "Amount" },
                    { title: "Status" },
                    { title: "Created on" },
                    { title: "Digital order" },
                    { title: "Gift Order" },
                    { title: "Actions" },
                ]}
            >
                {rowMarkup}
            </IndexTable>
        </div> : rowMarkupMobile
    )
}
