import {
  BlockStack,
  Box,
  Card,
  Divider,
  FormLayout,
  InlineGrid,
  Select,
  Text,
  TextField,
  useBreakpoints,
} from "@shopify/polaris";
import React, { useMemo } from "react";
import {
  renewalOptions,
  whatIsItOptions,
  whenMadeOptions,
  whoMadeItOptions,
  yesNoOptions,
} from "Src/Constant/SelectOptions";
import ConditionalAttributes from "./ConditionalAttributes";
import RecommendeAttributes from "./RecommendeAttributes";
import { useProductEditContext } from "../ProductEditProvider";
import { getOptionsFromObj, isConfigChecked } from "Src/function";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";

const EtsyAttribute = ({ t }: DIProps) => {
  const {
    localProduct,
    updateLocalProduct,
    templateOptionsMap,
    errors,
    removeError,
  } = useProductEditContext();
 const {mdUp} = useBreakpoints();
  const shippingTemplateOptions = useMemo(() => {
    return getOptionsFromObj(templateOptionsMap.shipping);
  }, [templateOptionsMap.shipping]);

  const policyTemplateOptions = useMemo(() => {
    return getOptionsFromObj(templateOptionsMap.policies);
  }, [templateOptionsMap.policies]);
  return (
    <BlockStack gap={"200"}>
      <Box
        paddingInlineEnd={{ xs: "400", xl: "0", md: "0", lg: "0", sm: "0" }}
        paddingInlineStart={{ xs: "400", xl: "0", md: "0", lg: "0", sm: "0" }}
      >
        <Text as="h2" variant="headingLg">
          {t("ETSY_ATTRIBUTES")}
        </Text>
      </Box>
      <Card>
        <BlockStack gap={"400"}>
          <BlockStack gap={"400"}>
            <Text as={"strong"} variant="headingMd" fontWeight="medium">
              {t("ABOUT_THE_LISTING")}
            </Text>
            <BlockStack gap={"300"}>
              <FormLayout>
                <FormLayout.Group condensed={mdUp ? true : false}>
                  <Select
                    id="who_made"
                    label={t("WHO_MADE_IT")}
                    options={whoMadeItOptions}
                    value={localProduct?.who_made ?? ""}
                    onChange={(val) => {
                      removeError("who_made");
                      updateLocalProduct({ who_made: val });
                    }}
                    error={errors?.who_made}
                    placeholder={t("SELECT")}
                  />
                  <Select
                    id="is_supply"
                    label={t("WHAT_IS_IT")}
                    options={whatIsItOptions}
                    value={localProduct?.is_supply ?? ""}
                    onChange={(val) => {
                      removeError("is_supply");
                      updateLocalProduct({ is_supply: val });
                    }}
                    error={errors?.is_supply}
                    placeholder={t("SELECT")}
                  />
                  <Select
                    id="when_made"
                    label={t("WHEN_MDID_YOU_MAKE_IT")}
                    options={whenMadeOptions}
                    value={localProduct?.when_made ?? ""}
                    onChange={(val) => {
                      removeError("when_made");
                      updateLocalProduct({ when_made: val });
                    }}
                    error={errors?.when_made}
                    placeholder={t("SELECT")}
                  />
                </FormLayout.Group>
              </FormLayout>
              <FormLayout>
              <FormLayout.Group condensed={mdUp ? true : false}>
              <Select
                    id="shipping_template_id"
                    label={t("SHIPPING_TEMPLATE")}
                    placeholder={t("SELECT")}
                    options={shippingTemplateOptions}
                    value={localProduct?.shipping_template_id}
                    onChange={(val) => {
                      removeError("shipping_template_id");
                      updateLocalProduct({ shipping_template_id: val });
                    }}
                    error={errors?.shipping_template_id}
                  />
                  <Select
                    id="policy"
                    label={t("POLICY_TEMPLATE")}
                    placeholder={t("SELECT")}
                    options={policyTemplateOptions}
                    value={localProduct?.policy}
                    onChange={(val) => {
                      removeError("policy");
                      updateLocalProduct({ policy: val });
                    }}
                    error={errors?.policy}
                  />
                </FormLayout.Group>
              </FormLayout>
            </BlockStack>
          </BlockStack>
          <Divider />
          <BlockStack gap={"400"}>
            <Text as={"strong"} variant="headingMd" fontWeight="medium">
              {t("ETSY_LISTING_ENHANCEMENT_ATTRIBUTES")}
            </Text>
            <BlockStack gap={"300"}>
              <Select
                label={t("IS_PERSONALIZE")}
                options={yesNoOptions}
                value={localProduct?.is_personalizable}
                onChange={(val) =>
                  updateLocalProduct({ is_personalizable: val })
                }
                helpText={t("WHEN_YES_LISTING_IS_PERSONALIZE")}
              />
              {isConfigChecked(localProduct?.is_personalizable) ? (
                <Card>
                  <InlineGrid gap="200">
                    <Select
                      id="personalization_is_required"
                      label={t("PERSONALIZATION_IS_REQ")}
                      placeholder={t("SELECT")}
                      options={yesNoOptions}
                      value={localProduct?.personalization_is_required}
                      onChange={(val) => {
                        updateLocalProduct({
                          personalization_is_required: val,
                        });
                      }}
                      helpText={t("IS_PERSONALIZE_HELPTXT")}
                    />
                    <TextField
                      id="personalization_char_count_max"
                      label="Personalization character limit"
                      placeholder={t("ENTER_LIMIT")}
                      value={localProduct?.personalization_char_count_max}
                      onChange={(val) => {
                        updateLocalProduct({
                          personalization_char_count_max: val,
                        });
                      }}
                      helpText={t("SET_A_CHAR_LIMIT_FOR_OPTIONS_LIKE_COLOR")}
                      autoComplete="off"
                      // error={error?.["personalization_char_count_max"]}
                      type="number"
                    />

                    <TextField
                      id="personalization_instructions"
                      label={t("PERSONALIZATION_INSTRUCTION")}
                      placeholder={t("ENTER_INSTRUCTION")}
                      value={localProduct?.personalization_instructions}
                      onChange={(val) => {
                        updateLocalProduct({
                          personalization_instructions: val,
                        });
                      }}
                      helpText={t("ENTER_INSTRUCTION_EXAMPLE")}
                      autoComplete="off"
                      // error={error?.["personalization_instructions"]}
                    />
                  </InlineGrid>
                </Card>
              ) : null}
              <Select
                label={t("IS_IT_CUSTOMIZABLE")}
                placeholder={t("SELECT")}
                options={yesNoOptions}
                value={localProduct?.is_customizable}
                onChange={(val) => updateLocalProduct({ is_customizable: val })}
                helpText={t("IS_IT_CUSTOMIZABLE_HELPTEXT")}
              />
              <Select
                label={t("RENEWAL_OPTION")}
                placeholder={t("SELECT")}
                options={renewalOptions}
                value={localProduct?.should_auto_renew}
                onChange={(val) =>
                  updateLocalProduct({ should_auto_renew: val })
                }
                helpText={t("RENEWAL_OPTION_HELPTEXT")}
              />
            </BlockStack>
          </BlockStack>
          <Divider />
          <ConditionalAttributes />
          <Divider />
          <RecommendeAttributes />
        </BlockStack>
      </Card>
    </BlockStack>
  );
};

export default DI(EtsyAttribute);
