import { BlockStack, Box, InlineGrid, InlineStack, SkeletonBodyText, SkeletonDisplayText, useBreakpoints } from '@shopify/polaris'
import React from 'react'
import PricingCardsSkeleton from './PricingCardsSkeleton';
import { DIProps } from 'Src/Interface/@core';
import { DI } from "Src/core";

interface PropsI extends DIProps {
  isOnboarding?: boolean;
}

const PricingSkeleton = ({isOnboarding}:PropsI) => {
  const { lgUp } = useBreakpoints();


  const comparePlansButton = (
    <div style={{ "width": "90px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
  );

  return (
    <>
    <BlockStack gap={isOnboarding ? "400" : "0"}>
      {isOnboarding && (
        <BlockStack gap="200">
          <InlineStack align="space-between">
          <div style={{ "width": "150px" }} className='skeleton-height-20'><SkeletonBodyText lines={1} /></div>
            {comparePlansButton}
          </InlineStack>
          <div style={{ "width": "35%" }}  className='skeleton-height-11'><SkeletonBodyText lines={1} /></div>
        </BlockStack>
      )}

      <InlineGrid columns={{ lg: 3 }} gap="200">
        <Box></Box>

        <InlineStack align="center">
          {/* add offer class to pricing-switcher-card div for offer design */}
       
            <div className="pricing-switcher-card">
              <InlineStack gap={"100"}>
                {[1,2].map((item: any) => (
                  <div key={item} style={{width: '76px'}}>
                  <SkeletonDisplayText size="medium" />
                  </div>
                ))}
              </InlineStack>
            </div>
        
         
        </InlineStack>

        {!isOnboarding && (
          <InlineStack align={lgUp ? "end" : "center"} blockAlign='center'>
            {comparePlansButton}
          </InlineStack>
        )}
      </InlineGrid>
    </BlockStack>

    <InlineGrid columns={{ lg: isOnboarding ? 1 : 3 }} gap="300">
      <PricingCardsSkeleton isOnboarding={isOnboarding} />
    </InlineGrid>
    </>
  )
}

export default DI(PricingSkeleton);
