import React, { useEffect, useState } from "react";
import { Badge, Banner, IndexTable, Text } from "@shopify/polaris";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";
import { apiUrls } from "Src/Constant/url";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import ModalAppBridge from "Src/Component/HelperComponents/ModalAppBridge";
import { getStatusBadge } from "Src/Component/HelperComponents/GlobalFunctions";
import { getFormattedDateTime, isValidValue } from "Src/function";
import { t } from "i18next";

interface PaymentsModalI {
  viewPayment: { open: boolean; payment_id: string };
  setViewPayment: React.Dispatch<
    React.SetStateAction<{ open: boolean; payment_id: string }>
  >;
}

const { viewPaymentData } = apiUrls;

const PaymentHistoryModal = (_props: PaymentsModalI & DIProps) => {
  const {
    request: { POST },
    redux: {
      settings: {
        accountDetails: { paymentHistory },
      },
    },
    viewPayment: { payment_id, open },
    setViewPayment,
  } = _props;

  const defaultPayment = paymentHistory?.[payment_id];
  const [payment, setPayment] = useState<any>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open || !defaultPayment.id || !defaultPayment.type) return;

    setLoading(true);
    POST(viewPaymentData, {
      charge_id: defaultPayment?.id,
      type: defaultPayment?.type,
    })
      .then((res) => {
        if (res.success && res?.data) {
          setPayment((prev: any) => ({
            activated_on: res?.data?.activated_on ?? prev?.activated_on ?? "",
            billing_on: res?.data?.billing_on ?? prev?.billing_on ?? "",
            created_at: res?.data?.created_at ?? prev?.created_at ?? "",
            currency: res?.data?.currency ?? prev?.currency ?? "",
            id: res?.data?.id ?? prev?.id ?? "",
            plan_name: res?.data?.plan_name ?? res?.data?.name ?? prev?.plan_name ?? "",
            price: res?.data?.price ?? prev?.price ?? "",
            status: res?.data?.status ?? prev?.status ?? "",
            test: res?.data?.test ?? prev?.test ?? "",
            trial_days: res?.data?.trial_days ?? prev?.trial_days ?? "",
            trial_ends_on:
              res?.data?.trial_ends_on ?? prev?.trial_ends_on ?? "",
            type: res?.data?.type ?? prev?.type ?? "",
            updated_at: res?.data?.updated_at ?? prev?.updated_at ?? "",
            errors: res?.data?.errors ?? null,
          }));
        } else {
          ToastAppBridge(res?.message, { isError: true });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [open]);

  if (!open || !payment) return;

  const { tone, updatedStatus } = payment?.status
    ? getStatusBadge(payment?.status)
    : {};
  const rows = {
    id: { title: t("ID"), value: payment.id },
    plan_name: { title: t("NAME"), value: payment.plan_name },
    price: { title: t("PRICE"), value: payment.price },
    billing_on: {
      title: t("BILLING_ON"),
      value: getFormattedDateTime(payment.billing_on),
    },
    status: {
      title: t("STATUS"),
      value: <Badge tone={tone}>{updatedStatus}</Badge>,
    },
    created_at: {
      title: t("CREATED_AT"),
      value: getFormattedDateTime(payment.created_at, true),
    },
    updated_at: {
      title: t("UPDATED_AT"),
      value: getFormattedDateTime(payment.updated_at, true),
    },
    activated_on: {
      title: t("ACTIVATED_ON"),
      value: getFormattedDateTime(payment.activated_on, true),
    },
    test: { title: t("TEST"), value: `${payment.test}` },
    trial_days: { title: t("TRIAL_DAYS"), value: payment.trial_days },
    trial_ends_on: {
      title: t("TRIAL_ENDS_ON"),
      value: getFormattedDateTime(payment.trial_ends_on),
    },
    currency: { title: t("CURRENCY"), value: payment.currency },
  };

  const rowMarkup = Object.entries(rows).map(
    ([key, { title, value }], index) => (
      <IndexTable.Row id={key} key={key} position={index}>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="medium" as="span">
            {title}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{value}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  return (
    <ModalAppBridge
      open={open}
      title={t("PAYMENT_DETAILS")}
      footer={
        <>
          <button
            onClick={() => {
              setViewPayment((prev) => ({
                ...prev,
                open: false,
                payment_id: "",
              }));
            }}
          >
            {t("CLOSE")}
          </button>
        </>
      }
      body={
        isValidValue(payment?.errors) ? (
          <Banner tone="critical">
            <Text as="p">
              {t("ERROR_FROM_SHOPIFY")} : {payment?.errors ?? t("DETAILS_NOT_FOUND")}
            </Text>
          </Banner>
        ) : (
          <IndexTable
            headings={[{ title: t("FIELDS") }, { title: t("VALUES") }]}
            itemCount={Object.keys(rows).length}
            selectable={false}
            resourceName={{ singular: t("PAYMENT"), plural: t("PAYMENTS") }}
            loading={loading}
          >
            {rowMarkup}
          </IndexTable>
        )
      }
      id="viewProductsModal"
      variant="small"
      onHide={() => {
        setViewPayment((prev) => ({ ...prev, open: false, payment_id: "" }));
      }}
    />
  );
};

export default DI(PaymentHistoryModal);
