import { BlockStack, Card, InlineStack, SkeletonBodyText, SkeletonDisplayText, SkeletonThumbnail } from '@shopify/polaris'
import React from 'react'

const TopSellingProductsSkeleton = () => {
    return (
        <Card>
            <BlockStack gap={"600"}>
                <InlineStack gap={"200"} blockAlign="center" align="start">
                    <div style={{ "width": "20px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                    <div style={{ "width": "60px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                </InlineStack>
                <BlockStack gap={"400"}>
                    {
                        Array.from({ length: 3 }, () =>
                            <InlineStack wrap={false} blockAlign='center' align='space-between'>
                                <InlineStack gap={"300"}>
                                    <SkeletonThumbnail size='medium' />
                                    <BlockStack gap={"500"}>
                                        <InlineStack gap={"100"}>
                                            <div style={{ "width": "100px" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <div style={{ "width": "20px" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                        </InlineStack>
                                        <InlineStack gap="200" align='start'>
                                            <div style={{ "width": "40px" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <div style={{ "width": "50px" }}>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                        </InlineStack>
                                    </BlockStack>
                                </InlineStack>
                                <SkeletonThumbnail size='small' />
                            </InlineStack>
                        )
                    }

                </BlockStack>
            </BlockStack>
        </Card>
    )
}

export default TopSellingProductsSkeleton
