import React from 'react'
import { BlockStack, Box, InlineStack, Page, SkeletonBodyText, SkeletonDisplayText, useBreakpoints } from '@shopify/polaris'
import PricingPlanSkeleton from './PricingPlanSkeleton';

const WithHeaderPricingSkeleton = () => {
    const { mdDown } = useBreakpoints();
    return (
        <>
            <Box borderBlockEndWidth="025" borderColor="border" paddingBlockEnd="400" paddingBlockStart={'100'}>
                <div className="custom_layout_width">
                    <div className='skeleton_page_header_spacing'>
                        <Page fullWidth>
                            {mdDown ? (
                                <InlineStack align='end' blockAlign='center' gap='050'>
                                    <div className='skeleton-height-32px' style={{ "width": "100px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <BlockStack gap='200'>
                                            <div style={{ "width": "150px" }} className='skeleton-height-26'><SkeletonBodyText lines={1} /></div>
                                            <SkeletonBodyText lines={2} />
                                        </BlockStack>
                                    </div>

                                </InlineStack>
                            ) : (
                                <InlineStack align='space-between' blockAlign='center'>
                                    <div style={{ width: "30%" }}>
                                        <BlockStack gap='100'>
                                            <div style={{ width: "150px" }} className='skeleton-height-26'><SkeletonBodyText lines={1} /></div>
                                            <div className='custom-height_skel'><SkeletonBodyText lines={1} /></div>
                                        </BlockStack>
                                    </div>

                                    <div style={{ "width": "100px" }}>
                                        <SkeletonDisplayText size='small' />
                                    </div>

                                </InlineStack>
                            )}
                        </Page>
                    </div>
                </div>
            </Box>
            <PricingPlanSkeleton />
        </>
    )
}

export default WithHeaderPricingSkeleton
