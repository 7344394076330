import {
  BlockStack,
  Box,
  Card,
  Collapsible,
  Icon,
  InlineStack,
  Text,
} from "@shopify/polaris";
import { ChevronUpIcon, ChevronDownIcon } from "@shopify/polaris-icons";
import { RuleI } from "Src/Interface/@Profile";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";
import React from "react";

const InventoryRule = ({
  handleToggle,
  opencard,
  ruleData,
  t,
}: RuleI & DIProps) => {
  const { inventory } = ruleData;
  return (
    <div className="custom_accordion_Card">
        <div
            onClick={() => handleToggle("inventory")}
            style={{ cursor: "pointer" }}
          >
      <Card roundedAbove="sm">
        <BlockStack gap="200">
        
            <InlineStack align="space-between">
              <Text as="h6" variant="headingSm" fontWeight="medium">
                {t("INVENTORY_TEMPLATE")}
              </Text>
              <Box>
                <Icon
                  source={
                    opencard === "inventory" ? ChevronUpIcon : ChevronDownIcon
                  }
                  tone="base"
                />
              </Box>
            </InlineStack>
         
          <Collapsible
            open={opencard === "inventory"}
            id="basic-collapsible"
            transition={{
              duration: "300ms",
              timingFunction: "ease-in-out",
            }}
            expandOnPrint
          >
            {inventory ? (
              <BlockStack gap={"100"}>
                <InlineStack align="space-between" blockAlign="center">
                  <Text as="strong" variant="bodyMd">
                    {t("NAME")}
                  </Text>
                  <Text as="strong" variant="bodyMd">
                    {inventory.template_name__inventory}
                  </Text>
                </InlineStack>
                <InlineStack align="space-between" blockAlign="center">
                  <Text as="strong" variant="bodyMd">
                    {t("MIN_INV")}
                  </Text>
                  <Text as="strong" variant="bodyMd">
                    {inventory.min_inventory_value}
                  </Text>
                </InlineStack>
                <InlineStack align="space-between" blockAlign="center">
                  <Text as="strong" variant="bodyMd">
                    {t("MAX_INV_ON_ETSY")}
                  </Text>
                  <Text as="strong" variant="bodyMd">
                    {inventory.send_continue_qty === "send_shopify"
                      ? t("SEND_AS_SHOPIFY_INV")
                      : t("SEND_999")}
                  </Text>
                </InlineStack>
              </BlockStack>
            ) : (
              <>{t("NO_PREVIEW_AVAILABLE")}</>
            )}
          </Collapsible>
        </BlockStack>
      </Card>
      </div>
    </div>
  );
};

export default DI(InventoryRule);
