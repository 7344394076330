import { Box, Card, SkeletonDisplayText } from '@shopify/polaris'
import React from 'react'

const TabSkeleton = () => {
    return (
        <Box>
            <Card padding={"400"}>
                <ul className="tab-buttons">
                    {Array.from({ length: 5 }, (index:any) =>
                        <li key={index}>
                            <SkeletonDisplayText size='medium' />
                        </li>
                    )}
                </ul>
            </Card>
        </Box>
    )
}

export default TabSkeleton
