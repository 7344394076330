import { AvatarFR, AvatarUS } from "./assets/icons/Icons";

export const languageItems = [
  {
    code: "en-US",
    content: "English",
    icon: AvatarUS,
  },
  {
    code: "fr",
    content: "French",
    icon: AvatarFR,
  },
];

export const DEFAULT_LANGUAGE = "en-US";

export const regexExpressions = {
  NUMBER_WITHOUT_DOT: /^[0-9]\d*$/,
  NUMBER_CHECK_WITH_DOT: /^\d*\.?\d*$/,
};

export const PRODUCT_STATUS_FILTER_KEY = "[etsy_product_status][]";
export const PRODUCT_PROFILE_STATUS_FILTER_KEY = "[selected_tab]"
export const ORDER_STATUS_FILTER_KEY = "order_status";
