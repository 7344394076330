import React, { useEffect, useState } from "react";
import "./App.css";
import "../i18n/i18nextsetup";
import { DI } from "./core";
import { DIProps } from "./Interface/@core";
import { login, logout } from "./Redux/Slices/authSlice";
import Panel from "./Component/Panel";
import { useShopify } from "./ShopifyAppBridgeProvider";
import { parseJwt, validateToken } from "./function";
import ScrollToTop from "./Component/HelperComponents/ScrollToTop";
import { subscription_level } from "./Constant/helpData";
import FullPageLoader from "./Component/FullPageLoader/FullPageLoader";
import { apiUrls } from "./Constant/url";
const wind: any = window;
const $zoho = wind.$zoho || {};
function App({
  redux,
  dispatch,
  request: { POST, GET },
  location,
  AppBridgeExternalRoute,
  globalState: { set, get },
}: Readonly<DIProps>) {
  const {
    auth: { isLoggedIn },
    userDetails: { shopifyShop },
    dashboard: {
      accountInfo: {
        clientInfo: { purchase_status },
      },
    },
  } = redux;
  const { app } = useShopify();
  const isEmbedded = get("isEmbed") === "true";
  let bypassLogin = false;
  if (location.pathname === "/etsy-redirect") {
    bypassLogin = true;
  }

  if (!validateToken()) {
    dispatch(logout());
  }
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      console.log("Auto updated to new version!!");
      window.location.reload();
    });
  }
  const [isYii, setIsYii] = useState(true);
  const fetchTempAccountinfo = async () => {
    isYii &&
      (await GET(apiUrls.getAccountInfo).then((res) => {
        if (
          res?.data?.client_info?.options?.login_server?.toLowerCase() === "yii"
        ) {
          setIsYii(true);
          const getShopifyQuery = get("getShopifyQuery");
          AppBridgeExternalRoute(
            app,
            process.env.REACT_APP_OLD_UI_URL + "?" + getShopifyQuery
          );
        } else {
          setIsYii(false);
        }
      }));
  };
  const setZohoScript = (data: any) => {
    if ($zoho) {
      $zoho.salesiq?.visitor?.info({
        "Store URL": get("shop"),
        "Payment Status": subscription_level[parseInt(purchase_status)],
        "App Name": "etsy",
        FrameWork: "Shopify",
      });
      if ($zoho?.salesiq?.visitor?.name)
        $zoho.salesiq.visitor.name(data?.shop_owner);
      if ($zoho?.salesiq?.visitor?.email)
        $zoho.salesiq.visitor.email(data?.email);
      if ($zoho?.salesiq?.visitor?.contactnumber)
        $zoho?.salesiq?.visitor?.contactnumber(data?.phone);
    }
  };

  useEffect(() => {
    if (window.name !== "app-iframe") {
      $zoho?.salesiq?.floatbutton?.visible?.("hide");
    }
    if (shopifyShop) setZohoScript(shopifyShop);
  }, [purchase_status, shopifyShop]);
  useEffect(() => {
    if (!isLoggedIn && !bypassLogin) {
      let call_api = true;
      const query = new URLSearchParams(location.search);
      const getShopifyQuery = query.toString();
      set("getShopifyQuery", getShopifyQuery);
      const payload: any = {};
      query.forEach((e, key) => {
        if (
          ["data[bearer_token]", "user_token", "admin_user_token"].includes(key)
        ) {
          call_api = false;
          set("shop", parseJwt(e)?.shop_url);
          dispatch(
            login({
              authToken: e,
              isAdmin: ["user_token", "admin_user_token"].includes(key),
            })
          );
        }
        payload[key] = e;
      });

      call_api &&
        POST("installation/login", payload).then((res) => {
          const { success, data, redirect_to_old } = res;
          if (success) {
            if (redirect_to_old) {
              AppBridgeExternalRoute(
                app,
                process.env.REACT_APP_OLD_UI_URL + "?" + getShopifyQuery
              );
            } else {
              if (data.bearer_token) {
                POST(apiUrls.createWebhooks, {});
                set("getShopifyQuery", getShopifyQuery);
                dispatch(
                  login({ authToken: data.bearer_token, isAdmin: false })
                );
              } else if (data.auth_url) {
                AppBridgeExternalRoute(app, data.auth_url);
              } else if (isEmbedded) {
                window.location.reload();
              }
            }
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);
  if (isLoggedIn || bypassLogin) {
    fetchTempAccountinfo();
    if (isYii) {
      return <FullPageLoader />;
    }
    return (
      <ScrollToTop>
        <Panel />
      </ScrollToTop>
    );
  } else {
    return <FullPageLoader />;
  }
}

export default DI(App);
