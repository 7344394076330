import { BlockStack, Card, IndexTable, SkeletonBodyText } from '@shopify/polaris'
import React from 'react'

const VariantDetailsSkeleton = () => {
    const gridSkeletonRow = {
        id: '1',
        sku: <div className='failed-order-skelton' style={{ 'width': '100px' }}><SkeletonBodyText lines={1} /></div>,
        inventory: <div className='failed-order-skelton' style={{ 'width': '30px' }}><SkeletonBodyText lines={1} /></div>,
        inventoryRule: <div className='failed-order-skelton' style={{ 'width': '90px' }}><SkeletonBodyText lines={1} /></div>,
        price: <div className='failed-order-skelton' style={{ 'width': '100px' }}><SkeletonBodyText lines={1} /></div>,
        comparePrice: <div className='failed-order-skelton' style={{ 'width': '70px' }}><SkeletonBodyText lines={1} /></div>,
        weight: <div className='failed-order-skelton' style={{ 'width': '70px' }}><SkeletonBodyText lines={1} /></div>,
    }
    const rowMarkup = Array.from({ length: 10 }, () => gridSkeletonRow);
    const rowMarkupUpdated = rowMarkup.map((item: any, index: any) => (
        <IndexTable.Row
            id={item.id}
            key={index}
            position={index}
        >
            <IndexTable.Cell>
                {item.sku}
            </IndexTable.Cell>
            <IndexTable.Cell>
                {item.inventory}
            </IndexTable.Cell>
            <IndexTable.Cell>{item.inventoryRule}</IndexTable.Cell>
            <IndexTable.Cell>{item.price}</IndexTable.Cell>
            <IndexTable.Cell>{item.comparePrice}</IndexTable.Cell>
            <IndexTable.Cell>{item.weight}</IndexTable.Cell>
        </IndexTable.Row>
    ),
    );
    return (
        <Card>
            <BlockStack gap={"300"}>
                <div style={{ "width": "150px" }}>
                    <SkeletonBodyText lines={1} />
                </div>
                <Card padding={"0"}>
                    <div className="variant-table_vertical_scroll">
                        <IndexTable
                            selectable={false}
                            itemCount={2}
                            headings={[
                                { title: "SKU" },
                                { title: "Inventory" },
                                { title: "Inventory Rules" },
                                { title: "Price" },
                                { title: "Compare Price" },
                                { title: "Weight" },
                            ]}
                        >
                            {rowMarkupUpdated}
                        </IndexTable>
                    </div>
                </Card>
            </BlockStack>
        </Card>
    )
}

export default VariantDetailsSkeleton
