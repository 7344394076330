export const ToastAppBridge = (
  message: string,
  obj: { isError?: boolean; duration?: number } = {
    isError: false,
    duration: 3000,
  }
) => {
  try {
    shopify.toast.show(message, obj);
  } catch {
    console.log({ resType: "Toast", message: message, isError: obj.isError });
  }
};
