import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ChartDataFormatI,
  DashboardAccountInfoI,
  DashboardInitialStateI,
  TopSellingProductI,
} from "Src/Interface/@core";

const initialState: DashboardInitialStateI = {
  orders: {
    data: [],
    labels: [],
    keys:[],
    total: 0,
  },
  products: {
    data: [],
    labels: [],
    keys:[],
    total: 0,
  },
  revenueData: {
    data: [],
    labels: [],
    keys:[],
    total: 0,
  },
  topSellingProducts: [],
  accountInfo: {
    productLimit: 0,
    orderLimit: 0,
    ordersUsed: 0,
    productsUsed: 0,
    activePlan: "",
    planAmt: "",
    duration: "",
    billingDate: "",
    activated_on: "",
    clientInfo: {
      options: null,
      install_status: "",
      install_date: "",
      purchase_status: "",
      last_login_time: "",
      expire_date: "",
      created_at: "",
      updated_at: "",
      imported_product: "",
      do_not_contact: "",
      product_count: "",
      order_count: "",
      is_multilocation: "",
      import_limit: "",
    },
  },
  showReview: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    saveOrdersData: (state, action: PayloadAction<ChartDataFormatI>) => {
      state.orders = { ...action.payload };
    },
    saveProductsData: (state, action: PayloadAction<ChartDataFormatI>) => {
      state.products = { ...action.payload };
    },
    saveTopSellingProducts: (
      state,
      action: PayloadAction<TopSellingProductI[]>
    ) => {
      state.topSellingProducts = action.payload;
    },
    saveRevenueData: (state, action: PayloadAction<ChartDataFormatI>) => {
      state.revenueData = { ...action.payload };
    },
    saveAccountInfo: (state, action: PayloadAction<DashboardAccountInfoI>) => {
      state.accountInfo = { ...action.payload };
    },
    saveShowReview: (state, action: PayloadAction<boolean>) => {
      state.showReview = action.payload;
    },
  },
});

export const {
  saveOrdersData,
  saveProductsData,
  saveRevenueData,
  saveTopSellingProducts,
  saveAccountInfo,
  saveShowReview,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
