import { BlockStack, Box, Card, Divider, InlineStack, SkeletonBodyText } from '@shopify/polaris'
import React from 'react'

const ActivityCardSkeleton = () => {
    return (
        <Card>
            <BlockStack gap={"600"}>
                <BlockStack gap={"300"}>
                    <InlineStack gap={"200"} align='space-between'>
                        <div style={{ "width": "50px" }}>
                            <SkeletonBodyText lines={1} />
                        </div>
                        <div style={{ "width": "50px" }}>
                            <SkeletonBodyText lines={1} />
                        </div>
                    </InlineStack>
                    <SkeletonBodyText lines={2} />
                </BlockStack>
                <Card>
                    <BlockStack gap={"400"}>
                        {Array.from({ length: 3 }, () =>
                            <BlockStack gap={'500'}>
                                <InlineStack wrap={false} gap={"300"}>
                                    <div className='skeleton-height-20' style={{ "width": "25px" }}>
                                        <SkeletonBodyText lines={1} />
                                    </div>
                                    <BlockStack gap={"500"}>
                                        <BlockStack gap={"400"}>
                                            <div style={{ "width": "150px" }} className='skeleton-height-11'>
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <div style={{ "width": "140px" }} >
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <div style={{ "width": "150px" }} >
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                            <div style={{ "width": "120px" }} >
                                                <SkeletonBodyText lines={1} />
                                            </div>
                                        </BlockStack>
                                        <div style={{ "width": "120px" }} >
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </BlockStack>
                                </InlineStack>
                                <Divider />
                            </BlockStack>
                        )}
                    </BlockStack>
                </Card>
            </BlockStack>
        </Card>
    )
}

export default ActivityCardSkeleton
