import React, { useState } from "react";
import {
  ActionList,
  Badge,
  BlockStack,
  Button,
  Icon,
  IndexTable,
  InlineStack,
  Link,
  Popover,
  Text,
  useBreakpoints,
} from "@shopify/polaris";
import { MenuVerticalIcon, ViewIcon } from "@shopify/polaris-icons";
import { useOrderContext } from "../OrderProvider";
import { orderStatus } from "../Helper";
import { isoCodeToCountryMap } from "Src/Constant/helpData";
import { capitalizeWords } from "Src/Component/HelperComponents/GlobalFunctions";
import { DI } from "Src/core";
import { apiUrls } from "Src/Constant/url";
import { DIProps } from "Src/Interface/@core";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { getFormattedDateTime, getTranslationKey } from "Src/function";
import { getTrimLength } from "Src/Component/CharacterLimitSet";
import { TFunction } from "i18next";

const { syncShippedOrders } = apiUrls;
export const GetBadgeForStatus = (status: string = "", t: TFunction): any => {
  const STATUS = status ? t(getTranslationKey(status)) : status;
  switch (STATUS) {
    case "Paid":
    case "Completed":
      return <Badge tone="success">{STATUS}</Badge>;
    case "Failed":
      return <Badge tone="critical">{STATUS}</Badge>;
    case "Partial Refunded":
      return (
        <Badge progress="partiallyComplete" tone="info">
          {STATUS}
        </Badge>
      );

    default:
      return <Badge>{STATUS}</Badge>;
  }
};

const AllOrders = ({
  selectedResources,
  t,
  globalState: { get },
  request: { POST },
  navigate,
  fetchOrders,
  setProcessLoading,
}: DIProps & {
  selectedResources: any[];
  fetchOrders: () => void;
  setProcessLoading: (e: boolean) => void;
}) => {
  const na_case = "--";
  const shopUrl = get("shop") ?? "";
  const shopName = shopUrl?.split(".")?.[0];
  const { orders, setModalAction } = useOrderContext();
  const [popoverActive, setPopoverActive] = useState(-1);
  const { mdDown } = useBreakpoints();

  return orders.map(
    (
      {
        shopify_order_name,
        receipt_id,
        shopify_order_id,
        order_data,
        order_total,
        status,
        created_at,
        digital_order,
        error,
        id,
      }: any,
      index: any
    ) => {
      const stringStatus = status.toString();
      const getActionList = () => {
        const Actions = {
          "ship-order": {
            content: t("SHIP_ORDER"),
            onAction: () =>
              setModalAction({
                id: receipt_id,
                type: "ship-order",
                isOpen: true,
              }),
          },
          "manual-order-create": {
            content: t("LINE_ITEM_MAPPING"),
            onAction: () =>
              setModalAction({
                id: receipt_id,
                type: "manual-order-create",
                isOpen: true,
                data: {
                  lineItems: order_data.transactions,
                },
              }),
          },
          "email-update": {
            content: t("EMAIL_UPDATE"),
            onAction: () =>
              setModalAction({
                id: receipt_id,
                type: "email-update",
                isOpen: true,
                data: { existing_email: order_data.buyer_email },
              }),
          },
          "create-order-on-shopify": {
            content: t("CREATE_ORDER_ON_SHOPIFY"),
            onAction: () => {
              setProcessLoading(true);
              POST(syncShippedOrders, {
                action: "sync-order",
                receipt_id: [receipt_id],
              }).then((e) => {
                e.success && fetchOrders();
                ToastAppBridge(e.message, { isError: !e.success });
                setProcessLoading(false);
              });
            },
          },
          "sync-shipped-order": {
            content: t("SYNC_SHIPPED_ORDER"),
            onAction: () => {
              setProcessLoading(true);
              POST(syncShippedOrders, {
                action: "ship-order",
                receipt_id: [receipt_id],
              }).then((e) => {
                e.success && fetchOrders();
                setProcessLoading(false);
                ToastAppBridge(e.message, { isError: !e.success });
              });
            },
          },
          "sync-order-status": {
            content: t("SYNC_ETSY_ORDER_STATUS"),
            onAction: () => {
              setProcessLoading(true);
              POST(syncShippedOrders, {
                action: "status-update",
                receipt_id: [receipt_id],
              }).then((e) => {
                e.success && fetchOrders();
                setProcessLoading(false);
                ToastAppBridge(e.message, { isError: !e.success });
              });
            },
          },
        };

        const ReturnElement: any = [];
        if (["10", "3", "5"].includes(stringStatus)) {
          ReturnElement.push(Actions["ship-order"]);
        }
        if (
          ["10"].includes(stringStatus) &&
          (error?.includes("sku_not_available") ||
            error?.includes("Unable to reserve inventory"))
        ) {
          ReturnElement.push(Actions["manual-order-create"]);
        }
        if (
          ["10"].includes(stringStatus) &&
          (error?.includes("contains an invalid domain name") ||
            error?.includes("is invalid"))
        ) {
          ReturnElement.push(Actions["email-update"]);
        }
        if (!shopify_order_id) {
          ReturnElement.push(Actions["create-order-on-shopify"]);
        }
        if (shopify_order_id && !["7", "4", "13"].includes(stringStatus)) {
          ReturnElement.push(Actions["sync-shipped-order"]);
        }
        return [...ReturnElement, Actions["sync-order-status"]];
      };

      const desktopMarkUp = (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(receipt_id)}
          position={index}
        >
          <IndexTable.Cell>
            <InlineStack>
              <Link dataPrimaryLink></Link>
              <div
                className="receipt-view"
                onClick={() => {
                  navigate("view", {
                    state: { receipt_id, shopify_order_id },
                  });
                }}
              >
                <InlineStack gap="100" wrap={false}>
                  <Button variant="monochromePlain" onClick={() => {}}>
                    {" "}
                    {receipt_id}
                  </Button>
                  <ViewIcon width={20} fill="#4a4a4a" />
                </InlineStack>
              </div>
            </InlineStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div onClick={(e) => e.stopPropagation()}>
              {shopify_order_name ? (
                <Link
                  onClick={() => {
                    shopName &&
                      window.open(
                        `https://admin.shopify.com/store/${shopName}/orders/${shopify_order_id}`
                      );
                  }}
                >
                  {" "}
                  {getTrimLength(shopify_order_name, 25)}
                </Link>
              ) : (
                na_case
              )}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {isoCodeToCountryMap[order_data.country_iso] ?? na_case}
          </IndexTable.Cell>
          <IndexTable.Cell>{order_data.name}</IndexTable.Cell>
          <IndexTable.Cell>
            {order_data.currency_code + " " + order_total}
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap={"100"}>
              <div>
                {GetBadgeForStatus(orderStatus[parseInt(stringStatus)], t)}
              </div>
              {error && (
                <div
                  className="error-badge"
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalAction({
                      id: receipt_id,
                      isOpen: true,
                      type: "error-modal",
                      data: error,
                    });
                  }}
                >
                  <Badge tone="critical">{t("ERROR")}</Badge>
                </div>
              )}
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {getFormattedDateTime(created_at, true)}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {t(order_data.transactions[0].is_digital ? "YES" : "NO")}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {t(order_data.is_gift ? "YES" : "NO")}
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Popover
              active={popoverActive === receipt_id}
              activator={
                <div onClick={(e) => e.stopPropagation()}>
                  <Button
                    icon={<Icon source={MenuVerticalIcon} tone="base" />}
                    onClick={() => {
                      document.body.click();
                      setPopoverActive(
                        popoverActive === receipt_id ? -1 : receipt_id
                      );
                    }}
                  />
                </div>
              }
              autofocusTarget="first-node"
              onClose={() => setPopoverActive(-1)}
            >
              <div onClick={(e) => e.stopPropagation()}>
                <ActionList actionRole="menuitem" items={getActionList()} />
              </div>
            </Popover>
          </IndexTable.Cell>
        </IndexTable.Row>
      );

      const mobileMarkUp = (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(receipt_id)}
          position={index}
        >
          <div className="mobile_card_item_inner">
            <InlineStack gap="300" wrap={false} align="space-between">
              <div style={{ flex: 1 }}>
                <BlockStack gap="200">
                  <InlineStack align="space-between">
                    <InlineStack gap="200" blockAlign="center">
                      <div
                        className="receipt-view"
                        onClick={() => {
                          navigate("view", {
                            state: { receipt_id, shopify_order_id },
                          });
                        }}
                      >
                        <Button variant="monochromePlain" onClick={() => {}}>
                          {receipt_id}
                        </Button>
                      </div>
                      <Text as="span" variant="bodyXs">
                        •
                      </Text>
                      <div onClick={(e) => e.stopPropagation()}>
                        {shopify_order_name ? (
                          <Link
                            onClick={() => {
                              shopName &&
                                window.open(
                                  `https://admin.shopify.com/store/${shopName}/orders/${shopify_order_id}`
                                );
                            }}
                          >
                            {" "}
                            {getTrimLength(shopify_order_name, 25)}
                          </Link>
                        ) : (
                          na_case
                        )}
                      </div>
                    </InlineStack>

                    <Text as="p" variant="bodyLg" fontWeight="medium">
                      {order_data.currency_code + " " + order_total}
                    </Text>
                  </InlineStack>

                  <BlockStack gap="100">
                    <InlineStack blockAlign="center" gap="100" align="start">
                      {order_data.name}
                      <Text as="span" variant="bodyXs">
                        •
                      </Text>
                      {isoCodeToCountryMap[order_data.country_iso] ?? na_case}
                    </InlineStack>

                    <InlineStack gap={"100"} align="start">
                      <div>
                        {GetBadgeForStatus(
                          orderStatus[parseInt(stringStatus)],
                          t
                        )}
                      </div>
                      {error && (
                        <div
                          className="error-badge"
                          onClick={(e) => {
                            e.stopPropagation();
                            setModalAction({
                              id: receipt_id,
                              isOpen: true,
                              type: "error-modal",
                              data: error,
                            });
                          }}
                        >
                          <Badge tone="critical">Error</Badge>
                        </div>
                      )}
                    </InlineStack>
                  </BlockStack>

                  <BlockStack gap="100">
                    <Text as="p" variant="bodyMd" tone="subdued">
                      Gift Order: {order_data.is_gift ? "Yes" : "No"}
                    </Text>

                    <Text as="p" variant="bodyMd" tone="subdued">
                      Digital Order:{" "}
                      {order_data.transactions[0].is_digital ? "Yes" : "No"}
                    </Text>

                    <Text as="p" variant="bodyMd" tone="subdued">
                      Created on: {getFormattedDateTime(created_at, true)}
                    </Text>
                  </BlockStack>
                </BlockStack>
              </div>

              <Popover
                active={popoverActive === receipt_id}
                activator={
                  <div onClick={(e) => e.stopPropagation()}>
                    <Button
                      icon={<Icon source={MenuVerticalIcon} tone="base" />}
                      onClick={() => {
                        document.body.click();
                        setPopoverActive(
                          popoverActive === receipt_id ? -1 : receipt_id
                        );
                      }}
                    />
                  </div>
                }
                autofocusTarget="first-node"
                onClose={() => setPopoverActive(-1)}
              >
                <div onClick={(e) => e.stopPropagation()}>
                  <ActionList actionRole="menuitem" items={getActionList()} />
                </div>
              </Popover>
            </InlineStack>
          </div>
        </IndexTable.Row>
      );

      return mdDown ? mobileMarkUp : desktopMarkUp;
    }
  );
};

export default DI(AllOrders);
