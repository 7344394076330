import {
  AutoSelection,
  Banner,
  Bleed,
  BlockStack,
  Box,
  Card,
  Checkbox,
  Combobox,
  Divider,
  FormLayout,
  Icon,
  InlineStack,
  Link,
  Listbox,
  Select,
  Tag,
  Text,
  TextField,
} from "@shopify/polaris";
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { DragHandleIcon } from "@shopify/polaris-icons";
import Sortable, { sortableArray } from "../Sortable/Sortable";
import { DIProps, ObjI, ObjStrI } from "Src/Interface/@core";
import { DI } from "Src/core";
import { shopifyTagsMap } from "Src/Constant/helpData";
import CountryTaxMapping from "./CountryTaxMapping";
import { isConfigChecked } from "Src/function";
import ShippingMethodMapping from "./ShippingMethodMapping";
import ShippingCarrierMapping from "./ShippingCarrierMapping";
import { shopifyCarrierOptions } from "Src/Constant/SelectOptions";

interface PropsI extends DIProps {
  config: any;
  changeHandlerConfig: (
    key: string,
    toBeToggled: boolean,
    value?: string | string[] | ObjI,
    parentKey?: string
  ) => void;
}

const OrderManagement = ({ config, changeHandlerConfig, t }: PropsI) => {
  const orderHelper: { [key: string]: ReactNode } = useMemo(
    () => ({
      gift_message: (
        <InlineStack wrap={false} gap="200">
          <Box>
            <Icon source={DragHandleIcon} tone="base" />
          </Box>
          <BlockStack gap="100">
            <Text variant="bodyMd" as="p">
              {t("GIFT_MESSAGE")}
            </Text>
            <Text variant="bodyMd" as="p" tone="subdued">
              {t("GIFT_MESSAGE_DESC")}
            </Text>
          </BlockStack>
        </InlineStack>
      ),
      message_from_buyer: (
        <InlineStack wrap={false} gap="200">
          <Box>
            <Icon source={DragHandleIcon} tone="base" />
          </Box>
          <BlockStack gap="100">
            <Text variant="bodyMd" as="p">
              {t("BUYER_MESSAGE")}
            </Text>
            <Text variant="bodyMd" as="p" tone="subdued">
              {t("BUYER_MESSAGE_DESC")}
            </Text>
          </BlockStack>
        </InlineStack>
      ),
      default_note: (
        <InlineStack wrap={false} gap="200">
          <Box>
            <Icon source={DragHandleIcon} tone="base" />
          </Box>

          <TextField
            label={t("CUSTOM_MESSAGE")}
            placeholder={t("ENTER_CUSTOM_MESSAGE")}
            autoComplete=""
            helpText={t("CUSTOM_MESSAGE_HELPTEXT")}
            value={config?.order_note_management?.default_note}
            onChange={(val) => {
              changeHandlerConfig(
                "default_note",
                false,
                val,
                "order_note_management"
              );
            }}
          />
        </InlineStack>
      ),
    }),
    [changeHandlerConfig, config]
  );

  const noteOrder = useMemo(() => {
    return config?.order_note_management
      ? Object.keys(config?.order_note_management).map((key) => {
          return { id: key, content: orderHelper?.[key] ?? <></> };
        })
      : [];
  }, [config?.order_note_management, orderHelper]);

  const changeOrderHandler = (order: sortableArray) => {
    const order_note_management = order.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.id]:
          curr.id === "default_note"
            ? config?.order_note_management?.default_note
            : "1",
      };
    }, {});
    changeHandlerConfig("order_note_management", false, order_note_management);
  };

  const [shopifyTagsSelected, setShopifyTagsSelected] = useState<string[]>(
    config?.["order_default_tags"]
      ? config?.["order_default_tags"]?.map(
          (key: string) => shopifyTagsMap?.[key] ?? key
        )
      : []
  );
  const [customTag, setCustomTag] = useState("");

  // toggle selected shopify tags
  const updateShopifyTags = useCallback(
    (selected: string) => {
      const nextSelectedTags = new Set([...shopifyTagsSelected]);

      if (nextSelectedTags.has(selected)) {
        nextSelectedTags.delete(selected);
      } else {
        nextSelectedTags.add(selected);
      }
      setShopifyTagsSelected(Array.from(nextSelectedTags));
      const reverseShopifyTagsMaps: ObjStrI = Object.entries(
        shopifyTagsMap
      ).reduce((prev, curr) => {
        return Object.assign(prev, { [curr[1]]: curr[0] });
      }, {});

      const configTags = Array.from(nextSelectedTags).map((tag) => {
        return reverseShopifyTagsMaps?.[tag] ?? tag;
      });
      changeHandlerConfig("order_default_tags", false, configTags);
      setCustomTag("");
    },
    [shopifyTagsSelected]
  );

  // remove shopify tag handler
  const removeTag = useCallback(
    (tag: string) => () => {
      updateShopifyTags(tag);
    },
    [updateShopifyTags]
  );

  // all the shopify tags available in options
  const allShopifyTags = useMemo(() => {
    const savedTags = Object.values(shopifyTagsMap);
    return Array.from(new Set([...savedTags, ...shopifyTagsSelected].sort()));
  }, [shopifyTagsSelected]);

  const tagsOptionMarkup =
    allShopifyTags.length > 0
      ? allShopifyTags.map((option) => {
          return (
            <Listbox.Option
              key={option}
              value={option}
              selected={shopifyTagsSelected.includes(option)}
              accessibilityLabel={option}
            >
              <Listbox.TextOption
                selected={shopifyTagsSelected.includes(option)}
              >
                {option}
              </Listbox.TextOption>
            </Listbox.Option>
          );
        })
      : null;

  const tagsActionMarkup =
    customTag && !allShopifyTags.includes(customTag) ? (
      <Listbox.Action value={customTag}>{`Add "${customTag}"`}</Listbox.Action>
    ) : null;

  const tagsListboxMarkup =
    tagsOptionMarkup || tagsActionMarkup ? (
      <Listbox autoSelection={AutoSelection.None} onSelect={updateShopifyTags}>
        {tagsActionMarkup}
        {tagsOptionMarkup}
      </Listbox>
    ) : null;

  return (
    <Card>
      <BlockStack gap="400">
        <BlockStack gap="400">
          <BlockStack gap="200">
            <Text as="h2" variant="headingSm">
              {t("ORDER_MANAGEMENT")}
            </Text>
            <Text as="p" variant="bodyMd">
              {t("ORDER_MANGEMENT_DESC")}
            </Text>
          </BlockStack>

          <Bleed marginInlineStart="400" marginInlineEnd="400">
            <Divider />
          </Bleed>
        </BlockStack>
        <BlockStack gap={"200"}>
          <BlockStack gap={"200"}>
            <Text variant="bodyMd" as="h3" fontWeight="medium" tone="subdued">
              {t("SHOPIFY_SETTINGS")}
            </Text>

            <BlockStack gap={"200"}>
              <InlineStack gap="300" wrap={false}>
                <div className="inte-custom__switcher">
                  <Checkbox
                    label
                    checked={isConfigChecked(config["refund_order_management"])}
                    onChange={() =>
                      changeHandlerConfig("refund_order_management", true)
                    }
                  />
                </div>
                <BlockStack gap={"200"}>
                  <Text as="p" variant="bodyMd" fontWeight="medium">
                    {t("CANCEL_REFUND_ORDERS")}
                  </Text>
                  <BlockStack gap="300">
                    <Text as="p">
                      {t("CANCEL_REFUND_ORDERS_DESC")}
                    </Text>
                  </BlockStack>
                </BlockStack>
              </InlineStack>

              <Divider />

              <InlineStack gap="300" wrap={false}>
                <div className="inte-custom__switcher">
                  <Checkbox
                    label
                    checked={isConfigChecked(
                      config?.["shopify_order_name_as_etsy_receipt_id"]?.[
                        "is_enable"
                      ]
                    )}
                    onChange={() =>
                      changeHandlerConfig(
                        "is_enable",
                        true,
                        "",
                        "shopify_order_name_as_etsy_receipt_id"
                      )
                    }
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <BlockStack gap="100">
                    <BlockStack gap={"200"}>
                      <Text as="p" variant="bodyMd" fontWeight="medium">
                        {t("SHOPIFY_ORDER_NAME_AS_ETSY_RECEIPT_ID")}
                      </Text>
                      <Text as="p">
                        {t("SHOPIFY_ORDER_NAME_AS_ETSY_RECEIPT_ID_DESC")}
                      </Text>
                      {isConfigChecked(
                        config?.["shopify_order_name_as_etsy_receipt_id"]?.[
                          "is_enable"
                        ]
                      ) && (
                        <FormLayout>
                          <FormLayout.Group condensed>
                            <TextField
                              label="Prefix"
                              placeholder="Prefix"
                              labelHidden
                              value={
                                config?.[
                                  "shopify_order_name_as_etsy_receipt_id"
                                ]?.["prefix"]
                              }
                              onChange={(val) =>
                                changeHandlerConfig(
                                  "prefix",
                                  false,
                                  val,
                                  "shopify_order_name_as_etsy_receipt_id"
                                )
                              }
                              autoComplete="off"
                            />
                            <TextField
                              label={t("RECEIPT_ID")}
                              placeholder={t("RECEIPT_ID")}
                              labelHidden
                              autoComplete="off"
                              disabled
                            />
                            <TextField
                              label={t("SUFFIX")}
                              placeholder={t("SUFFIX")}
                              labelHidden
                              value={
                                config?.[
                                  "shopify_order_name_as_etsy_receipt_id"
                                ]?.["suffix"]
                              }
                              onChange={(val) =>
                                changeHandlerConfig(
                                  "suffix",
                                  false,
                                  val,
                                  "shopify_order_name_as_etsy_receipt_id"
                                )
                              }
                              autoComplete="off"
                            />
                          </FormLayout.Group>
                        </FormLayout>
                      )}
                    </BlockStack>
                    <Text as="p" variant="bodyMd" tone="subdued">
                      {t("SUFFIX_DESC")}
                    </Text>
                  </BlockStack>
                </div>
              </InlineStack>

              <Divider />

              <div className="padding-left44">
                <InlineStack gap="300" wrap={false}>
                  <div style={{ flex: 1 }}>
                    <BlockStack gap={"200"}>
                      <Text as="p" variant="bodyMd" fontWeight="medium">
                        {t("SEND_NOTE_TO_SHOPIFY_IN_ORDER")}
                      </Text>
                      <Text as="p">
                        {t("SEND_NOTE_TO_SHOPIFY_IN_ORDER_DESC")}
                      </Text>
                      <Card roundedAbove="xs">
                        <Sortable
                          customClass="sort-order-msg"
                          animationDuration={400}
                          data={noteOrder}
                          onChange={(newArr) => changeOrderHandler(newArr)}
                        />
                      </Card>
                    </BlockStack>
                  </div>
                </InlineStack>
              </div>
              <Divider />

              <InlineStack gap="300" wrap={false}>
                <div className="inte-custom__switcher">
                  <Checkbox
                    label
                    checked={isConfigChecked(
                      config?.["vat_setting"]?.["etsy_country_enable"]
                    )}
                    onChange={() => {
                      changeHandlerConfig(
                        "etsy_country_enable",
                        true,
                        "",
                        "vat_setting"
                      );
                    }}
                  />
                </div>
                <Box width="100%">
                  <BlockStack gap={"200"}>
                    <Text as="p" variant="bodyMd" fontWeight="medium">
                      {t("ORDER_TAX_SPLIT_SETTING")}
                    </Text>
                    <Text as="p">
                      {t("ORDER_TAX_SPLIT_SETTING_DESC")}
                    </Text>

                    {isConfigChecked(
                      config?.["vat_setting"]?.["etsy_country_enable"]
                    ) && (
                      <>
                        <BlockStack gap="200">
                          <Text as="p">
                            {t("CUSTOM_TAXES_WILL_BE_APPLIED_TO_SELECTED_ATTRIBUTES_IF_TAX_SPLITTING")}
                          </Text>
                          <Checkbox
                            label={t("ARE_TAXES_INCLUDED")}
                            helpText={t("ARE_TAXES_INCLUDED_HELPTEXT")}
                            checked={isConfigChecked(
                              config?.["vat_setting"]?.["taxes_included"]
                            )}
                            onChange={() => {
                              changeHandlerConfig(
                                "taxes_included",
                                true,
                                "",
                                "vat_setting"
                              );
                            }}
                          />
                          <Text as="p">
                            {t("SELECT_ATTRIBUTES_WEHRE_CUSTOM_TAX_IS_APPLIED")}
                          </Text>
                          <Checkbox
                            label={t("LINE_ITEM")}
                            helpText={t("LINE_ITEM_HELPTEXT")}
                            checked={isConfigChecked(
                              config?.["split_applied_on"]?.["total_line_items"]
                            )}
                            onChange={() => {
                              changeHandlerConfig(
                                "total_line_items",
                                true,
                                "",
                                "split_applied_on"
                              );
                            }}
                          />
                          <Checkbox
                            label={t("SHIPPING_COST")}
                            helpText={t("SHIPPING_COST_HELPTEXT")}
                            checked={isConfigChecked(
                              config?.["split_applied_on"]?.["shipping_cost"]
                            )}
                            onChange={() => {
                              changeHandlerConfig(
                                "shipping_cost",
                                true,
                                "",
                                "split_applied_on"
                              );
                            }}
                          />
                        </BlockStack>
                        <CountryTaxMapping />
                      </>
                    )}
                  </BlockStack>
                </Box>
              </InlineStack>
              <Divider />
              <div className="padding-left44">
                <div className="combobox-tags">
                  <BlockStack gap="200">
                    <Text variant="bodyMd" as="h4" fontWeight="medium">
                      {t("SHOPIFY_ORDER_TAGS")}
                    </Text>

                    <Text variant="bodyMd" as="h4">
                      {t("SHOPIFY_ORDER_TAGS_DESC")}
                    </Text>

                    <Combobox
                      allowMultiple
                      activator={
                        <Combobox.TextField
                          placeholder={t("SHOPIFY_ORDER_TAGS_PLACEHOLDER")}
                          helpText={t("SHOPIFY_ORDER_TAGS_HELPTXT")}
                          autoComplete="off"
                          label={t("SEARCH_TAGS")}
                          labelHidden
                          value={customTag}
                          suggestion={customTag}
                          verticalContent={
                            shopifyTagsSelected.length > 0 ? (
                              <InlineStack gap="100">
                                {shopifyTagsSelected.map((tag) => (
                                  <Tag
                                    key={`option-${tag}`}
                                    onRemove={removeTag(tag)}
                                  >
                                    {tag}
                                  </Tag>
                                ))}
                              </InlineStack>
                            ) : null
                          }
                          onChange={(tag) => {
                            shopifyTagsSelected.length < 10 &&
                              setCustomTag(tag);
                          }}
                        />
                      }
                    >
                      {tagsListboxMarkup}
                    </Combobox>
                  </BlockStack>
                </div>
              </div>
              {/* new settings update start */}
              <Divider />

              <InlineStack gap="300" wrap={false}>
                <div className="inte-custom__switcher">
                  <Checkbox
                    label
                    checked={isConfigChecked(
                      config?.shipping_method_as_template_name
                    )}
                    onChange={() => {
                      changeHandlerConfig(
                        "shipping_method_as_template_name",
                        true
                      );
                    }}
                  />
                </div>
                <Box width="100%">
                  <BlockStack gap={"200"}>
                    <Text as="p" variant="bodyMd" fontWeight="medium">
                      {t("SHOPIFY_SHIPPING_METHOD_AS_ETSY_SHIPPING")}
                    </Text>
                    <Text as="p">
                      {t("SHOPIFY_SHIPPING_METHOD_AS_ETSY_SHIPPING_DESC")}
                    </Text>
                  </BlockStack>
                </Box>
              </InlineStack>
              <Divider />

              <div className="padding-left44">
                <BlockStack gap={"200"}>
                  <Text as="p" variant="bodyMd" fontWeight="medium">
                    {t("MAP_ETSY_WITH_SHOPIFY_SHIPPING_METHOD")}
                  </Text>
                  <Text as="p">
                    {t("MAP_ETSY_WITH_SHOPIFY_SHIPPING_METHOD_DESC")}
                  </Text>
                  <ShippingMethodMapping />
                </BlockStack>
              </div>
              {/* new settings update end */}
            </BlockStack>
          </BlockStack>

          <Bleed marginInlineStart="400" marginInlineEnd="400">
            <Divider />
          </Bleed>

          <BlockStack gap="200">
            <Text variant="bodyMd" as="h3" fontWeight="medium" tone="subdued">
              {t("ETSY_SETTINGS")}
            </Text>

            <BlockStack gap="200">
              <InlineStack gap="300" wrap={false}>
                <div className="inte-custom__switcher">
                  <Checkbox
                    label
                    checked={isConfigChecked(config["order_management"])}
                    onChange={() =>
                      changeHandlerConfig("order_management", true)
                    }
                  />
                </div>
                <BlockStack gap={"200"}>
                  <Text as="p" variant="bodyMd" fontWeight="medium">
                    {t("MANGE_ORDER_THROUGH_APP")}
                  </Text>
                  <Text as="p">
                    {t("MANGE_ORDER_THROUGH_APP_DESC")}
                  </Text>
                  {isConfigChecked(config["order_management"]) && (
                    <BlockStack>
                      <Checkbox
                        label={t("MANGE_ORDER_FOR_DIGITAL_LISTING")}
                        checked={isConfigChecked(
                          config["order_management_digital"]
                        )}
                        onChange={() =>
                          changeHandlerConfig("order_management_digital", true)
                        }
                        helpText={t("MANGE_ORDER_FOR_DIGITAL_LISTING_DESC")}
                      />
                    </BlockStack>
                  )}
                </BlockStack>
              </InlineStack>

              <Divider />

              <InlineStack gap="300" wrap={false}>
                <div className="inte-custom__switcher">
                  <Checkbox
                    label
                    checked={isConfigChecked(
                      config["order_shipment_management"]
                    )}
                    onChange={() =>
                      changeHandlerConfig("order_shipment_management", true)
                    }
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <BlockStack gap={"200"}>
                    <Text as="p" variant="bodyMd" fontWeight="medium">
                      {t("MANAGE_SHIPMENT_FROM_APP")}
                    </Text>
                    <Text as="p">
                      {/* {t("MANAGE_SHIPMENT_FROM_APP_DESC")} */}
                      {/* Enable to send shipment details from Shopify to Etsy when fulfilling orders from Shopify. */}
                      Enable to send shipment details from Shopify to Etsy when fulfilling orders from Shopify.
                    </Text>
                    {isConfigChecked(config["order_shipment_management"]) && (
                      <BlockStack gap="200">
                        <Checkbox
                          label={t("SHIP_ORDER_ON_ETSY_WITHOUT_TRACKING")}
                          helpText={t("SHIP_ORDER_ON_ETSY_WITHOUT_TRACKING_DESC")}
                          checked={isConfigChecked(
                            config["order_shipment_without_tracking"]
                          )}
                          onChange={() => {
                            changeHandlerConfig(
                              "order_shipment_without_tracking",
                              true
                            );
                          }}
                        />
                        <Banner tone="info">
                          {t("TRACKING_NUMBER_NOTICE")} 
                          <Link
                            target="_blank"
                            url="https://www.etsy.com/in-en/seller-handbook/article/introducing-a-new-streamlined-shipping/1020417808606#QT"
                          >
                            {t("FOR_MORE_DETAILS")}
                          </Link>
                        </Banner>
                        <Checkbox
                          label={t("EMAIL_ME_A_COPY_OF_FULFILLMENT")}
                          helpText={t("EMAIL_ME_A_COPY_OF_FULFILLMENT_HELPTEXT")}
                          checked={isConfigChecked(config["send_bcc"])}
                          onChange={() => {
                            changeHandlerConfig("send_bcc", true);
                          }}
                        />
                        <TextField
                          label={t("NOTE_TO_BUYER")}
                          autoComplete=""
                          placeholder={t("NOTE_TO_BUYER_PLACEHOLDER")}
                          helpText={t("NOTE_TO_BUYER_HELPTEXT")}
                          value={config["note_to_buyer"]}
                          onChange={(val) => {
                            changeHandlerConfig("note_to_buyer", false, val);
                          }}
                        />
                      </BlockStack>
                    )}
                  </BlockStack>
                </div>
              </InlineStack>

              {/* new settings update start */}

              <Divider />

              <div className="padding-left44">
                <Select
                  name={t("SELECT")}
                  options={shopifyCarrierOptions}
                  label={
                    <Text variant="bodyMd" as="span" fontWeight="medium">
                      {t("DEFAULT_CARRIER_NAME_SENT_ON_ETSY")}
                    </Text>
                  }
                  placeholder={t("DEFAULT_CARRIER_NAME_SENT_ON_ETSY_PLACEHOLDER")}
                  value={config?.shipment_default_carrier}
                  onChange={(val) => {
                    changeHandlerConfig("shipment_default_carrier", false, val);
                  }}
                  helpText={t("DEFAULT_CARRIER_NAME_SENT_ON_ETSY_HELPTEXT")}
                />
              </div>

              <Divider />

              <div className="padding-left44">
                <BlockStack gap={"200"}>
                  <Text as="p" variant="bodyMd" fontWeight="medium">
                    {t("MAP_SHOPIFY_WITH_ETSY_CARRIER_MAPPING")}
                  </Text>
                  <Text as="p">
                    {t("MAP_SHOPIFY_WITH_ETSY_CARRIER_MAPPING_DESC")}
                  </Text>

                  <ShippingCarrierMapping />
                </BlockStack>
              </div>

              {/* new settings update end */}
            </BlockStack>
          </BlockStack>
        </BlockStack>
      </BlockStack>
    </Card>
  );
};

export default DI(OrderManagement);
